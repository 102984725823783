// import { useState } from "react";
// import { data } from "./data";
import "./Section5.css";
import styles from "../../../../styles/index";
import main from "../../../../assets/ind/sec5/main.svg";
import shopper from "../../../../assets/ind/sec5/shopper.svg";
import storePickup from "../../../../assets/ind/sec5/storePickup.svg";
import deliveryOptions from "../../../../assets/ind/sec5/deliveryOptions.svg";
import storeDetails from "../../../../assets/ind/sec5/storeDetails.svg";
import deliveryPartners from "../../../../assets/ind/sec5/deliveryPartners.svg";
import productDetails from "../../../../assets/ind/sec5/productDetails.svg";
import deliveryDetails from "../../../../assets/ind/sec5/deliveryDetails.svg";
import TrackingDetails from "../../../../assets/ind/sec5/TrackingDetails.svg";

const Section5 = () => {
  // const [selected, setSelected] = useState("No Commission Fee");
  // const [selectedImg, setSelectedImg] = useState(data[0].img);

  // function onSelect(item) {
  //   setSelected(item.title);
  //   setSelectedImg(item.img);
  // }

  return (
    // <section className={`${styles.xPaddings}`}>
    //   <div className={`${styles.innerWidth} mx-auto py-6 `}>
    //     <div className="sec5_wrapper">
    //       <div className="sec5_top">
    //         <h2>Why Join Us?</h2>
    //         <span>
    //           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut arcu purus, condimentum fermentum lacus sit
    //           amet, interdum ullamcorper sem.
    //         </span>
    //       </div>
    //       <div className="sec5_bottom">
    //         <div>
    //           {data.map((item, index) => (
    //             <div
    //               className={`sec5_bottom_left ${selected === item.title && "sec5_selected"}`}
    //               onClick={() => onSelect(item)}
    //               key={index}
    //             >
    //               <div>
    //                 <span>{item.sno}</span>
    //                 <span> {item.title}</span>
    //               </div>
    //               <div style={{ fontSize: "26px" }}>&#8594;</div>
    //             </div>
    //           ))}
    //         </div>
    //         <div className={`sec5_bottom_right`}>
    //           <img src={selectedImg} alt="" className="sec5_bottom_right_img" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className={`sm:my-6 my-6  flex flex-col items-center  ${styles.xPaddings}  `}>
      <div className={`${styles.innerWidth} mx-auto flex justify-evenly flex-col h-full gap-8`}>
        <div className="text-center sm:mb-8 mb-6 space-y-4  ">
          <h1 className="sm:text-xl  text-[22px] leading-8 font-bold">Delivery Experience</h1>
          <p className="sm:text-base text-[16px] leading-8">
            Streamline Your Shipping and Logistics, With our end-to-end logistics solution, we provide the right
            logistics partner for you.
          </p>
        </div>

        <div className="hidden md:block">
          <img src={main} alt="" />
        </div>

        <div className="md:hidden flex flex-col items-center gap-4">
          <img src={shopper} alt="" />
          <img src={storePickup} alt="" />
          <img src={deliveryOptions} alt="" />
          <img src={storeDetails} alt="" />
          <img src={deliveryPartners} alt="" />
          <img src={productDetails} alt="" />
          <img src={deliveryDetails} alt="" />
          <img src={TrackingDetails} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Section5;
