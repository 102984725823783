import React from "react";
import styles from "../../../../styles";
import { sec1data } from "./data";

const Sec1 = ({ path }) => {
  let data;
  if (path === "claims") {
    data = sec1data.claims;
  } else if (path === "invoice") {
    data = sec1data.invoice;
  } else {
    data = sec1data.payment;
  }

  console.log(data);
  return (
    <section
      className={`bg-[#F9FAFB] ${styles.xPaddings} xl:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)]  `}
    >
      <div className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full flex items-center `}>
        <div className="flex  flex-col lg:flex-row  ">
          <div className="bg-white flex-1 space-y-8 sm:space-y-6 p-6 rounded-md  ">
            <div className="flex items-center gap-3">
              <img src={data.icon} alt="" className="w-6 h-6" />
              <h1 className="text-md font-bold ">{data.title}</h1>
            </div>
            <p className="text-base leading-7 text-justify">{data.p1}</p>
            <p className="text-base leading-7 text-justify">{data.p2}</p>
            {/* {path === "payment" && (
              <div className="space-y-2">
                <h5 className="font-semibold">Hint :</h5>
                <h5 className="font-semibold">What is Payment :</h5>
                <p className="text-base leading-7 text-justify">
                  Payment Mode, We make a payment via API and fully automated, Robust and agile, Validate Payment
                  Details
                </p>
              </div>
            )} */}
          </div>
          <div className="bg-[#F1F5F9] flex-1  rounded-md p-6 flex items-center justify-center ">
            <img src={data.img} alt="" className="w-full object-contain  " />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sec1;
