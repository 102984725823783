import React from "react";
import styles from "../../../styles";
import { green, purple, pink } from "./data";

const Sec4 = ({ color, shadow, title }) => {
  // const buttonName = ["Providers", "Supplier", "Aged Care Person"];
  // const [Provider, setProvider] = useState(true);
  // const [Acp, setAcp] = useState(false);
  // const [Supp, setSupp] = useState(false);
  // const [selectedButton, setSelectedButton] = useState(buttonName[0]);

  // const selected = (name) => {
  //   setSelectedButton(name);
  //   if (name === buttonName[0]) {
  //     setProvider(true);
  //     setSupp(false);
  //     setAcp(false);
  //   }
  //   if (name === buttonName[1]) {
  //     setAcp(true);
  //     setProvider(false);
  //     setSupp(false);
  //   }
  //   if (name === buttonName[2]) {
  //     setSupp(true);
  //     setAcp(false);
  //     setProvider(false);
  //   }
  // };
  let data;
  if (color === "green") {
    data = green;
  } else if (color === "pink") {
    data = pink;
  } else {
    data = purple;
  }

  return (
    // <section className="bg-[#F9FAFB]  sm:p-6 py-4 px-6 ">
    <section
      className={` ${
        styles.xPaddings
      } xl:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)] max-h-max ${
        shadow && "bg-[#F9FAFB]"
      }`}
    >
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-around gap-6 sm:gap-0 md:gap-0`}
      >
        <div className="text-center sm:mb-10 mb-4 ">
          <h1 className="sm:text-xl text-[22px] leading-8 font-bold mb-5">
            {/* Simplify and Strengthen Your Business Operations */}
            {title}
          </h1>
          <span className="sm:text-base text-[16px] leading-8">
            Our software fully automates and combines the invoice, claim and payment resolution. Improving the Aged Care
            and NDIS Payment.{" "}
          </span>
        </div>
        {/* <div className="hidden  ">
          <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane tab="Manage your Journey"> */}
        {/* {Provider && ( */}
        {/* <div className="flex justify-evenly items-center my-6 flex-wrap lg:gap-4 gap-12 flex-col sm:flex-row">
                {Providers.map((item, i) => (
                  <div className="p-4 sm:w-[350px] flex flex-col gap-4  bg-white rounded-md  sm:min-h-[455px] " key={i}>
                    <div className="flex items-center space-x-3">
                      <img src={item.icon} alt="icon" className="w-14 h-14" />
                      <h1 className="sm:text-[22px] sm:leading-8 text-[20px] font-semibold">{item.title}</h1>
                    </div>
                    <span className="text-base">{item.desc}</span>
                    <img src={item.img} alt="" className="" />
                  </div>
                ))}
              </div> */}
        {/* )} */}
        {/* {Supp && ( */}
        {/* <div className="flex justify-evenly items-center my-6 flex-wrap lg:gap-4 gap-7 flex-col sm:flex-row">
                {Supplier.map((item, i) => (
                  <div className="p-4 sm:w-[350px] flex flex-col gap-4 bg-white rounded-md  sm:min-h-[455px]" key={i}>
                    <div className="flex items-center space-x-3">
                      <img src={item.icon} alt="icon" className="w-14 h-14" />
                      <h1 className="sm:text-[22px] sm:leading-8 text-[20px] font-semibold">{item.title}</h1>
                    </div>
                    <span className="text-base">{item.desc}</span>
                    <img src={item.img} alt="" />
                  </div>
                ))}
              </div> */}
        {/* )} */}
        {/* {Acp && ( */}
        {/* <div className="flex justify-evenly items-center my-6 flex-wrap lg:gap-4 gap-7 flex-col sm:flex-row">
                {AgedCarePerson.map((item, i) => (
                  <div className="p-4 sm:w-[350px] flex flex-col gap-4 bg-white rounded-md  sm:min-h-[455px]" key={i}>
                    <div className="flex items-center space-x-3">
                      <img src={item.icon} alt="icon" className="w-14 h-14" />
                      <h1 className="sm:text-[22px] sm:leading-8 text-[20px] font-semibold">{item.title}</h1>
                    </div>
                    <span className="text-base">{item.desc}</span>
                    <img src={item.img} alt="" />
                  </div>
                ))}
              </div> */}
        {/* )} */}

        {/* <div className="flex justify-center gap-4 mt-10 mb-6 flex-wrap">
              {buttonName.map((button, i) => (
                <button
                  key={i}
                  className={`bg-[#E5E2EA] font-medium text-base p-1.5 px-3 rounded-full hover:bg-[#EF069A] hover:text-white ${
                    selectedButton === button && "bg-[#EF069A] text-white"
                  }`}
                  onClick={() => selected(button)}
                >
                  {button}
                </button>
              ))}
            </div> */}
        {/* </Tabs.TabPane>
            <Tabs.TabPane tab="Features you Love" key="2">
              <div className="flex flex-wrap gap-7 justify-evenly mt-7">
                {features.map((item, i) => (
                  <div
                    key={i}
                    className="flex gap-8 sm:w-[600px] sm:h-60 overflow-hidden sm:justify-evenly justify-center  items-start flex-col sm:flex-row"
                  >
                    <img src={item.img} alt="img" className="sm:w-2/4" />
                    <div>
                      <h4 className="text-[18px] leading-7">{item.title}</h4>
                      <span className="text-base">{item.desc}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="How we Build" key="3">
              <div className="text-center mb-14 mt-7">
                <h1 className="text-md font-semibold text-[#333333]">How we Build</h1>
                <span className="text-base ">
                  Through a combination of intelligent process automation, artificial intelligence, and automation and
                  orchestration, Care Commerce develop an automated workflow
                  <br />
                  which cuts significant time, money and hassle out of being involved in claims processes.
                </span>
              </div>
              <div className="flex flex-wrap justify-evenly gap-7">
                {HowWeBuild.map((item, i) => (
                  <div className="flex flex-col gap-8 sm:w-60 rounded-sm shadow-lg " key={i}>
                    <img src={item.img} alt="" />
                    <div className="p-3">
                      <h1 className="text-md font-semibold">{item.title}</h1>
                      <span>{item.desc}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div> */}

        {/* mobile view */}

        {/* <div className="flex flex-col items-center gap-4 px-3 ">
          <div className="flex justify-evenly items-center  flex-wrap sm:gap-6 gap-4 flex-col "> */}
        {/* <h1 className="text-[22px] font-bold">Manage your Journey</h1> */}
        <div
          className={`flex justify-evenly w-full flex-wrap lg:flex-nowrap p-6 items-center  rounded-md sm:gap-6 gap-8 min-h-max ${
            shadow && "bg-[#fff] shadow-2xl border border-gray-200 "
          }`}
        >
          {data.map((item, i) => (
            <div className="w-[350px] flex flex-col gap-6 rounded-md items-center" key={i}>
              <div className="p-5 xl:min-h-[220px] lg:min-h-[250px] md:h-[200px] shadow-2xl rounded-md   ">
                <div className="flex items-center space-x-3 mb-4">
                  <img src={item.icon} alt="icon" className="w-14 h-14" />
                  <h1 className="sm:text-[22px] text-[19px] leading-7 font-semibold">{item.title}</h1>
                </div>
                <p className=" md:text-[15px] xl:text-base leading-6 text-justify">{item.desc}</p>
              </div>
              <img src={item.img} alt="" className="h-56 " />
            </div>
          ))}
          {/* {Supplier.map((item, i) => (
                <div className="p-4  sm:w-[350px]  flex flex-col gap-4 bg-white rounded-md  sm:h-[466px]" key={i}>
                  <div className="flex items-center space-x-3">
                    <img src={item.icon} alt="icon" className="w-14 h-14" />
                    <h1 className="sm:text-[22px] text-[19px] leading-7">{item.title}</h1>
                  </div>
                  <span className="text-base leading-8">{item.desc}</span>
                  <img src={item.img} alt="" className="h-[227px] sm:w-[318px] sm:object-cover " />
                </div>
              ))}
              {AgedCarePerson.map((item, i) => (
                <div className="p-4  sm:w-[350px]  flex flex-col gap-4 bg-white rounded-md  sm:h-[466px]" key={i}>
                  <div className="flex items-center space-x-3">
                    <img src={item.icon} alt="icon" className="w-14 h-14" />
                    <h1 className="sm:text-[22px] text-[19px] leading-7">{item.title}</h1>
                  </div>
                  <span className="text-base leading-8">{item.desc}</span>
                  <img src={item.img} alt="" className="h-[227px] sm:w-[318px] sm:object-cover " />
                </div>
              ))} */}
        </div>
      </div>
      <div className="flex justify-evenly items-center  flex-wrap sm:gap-6 gap-4 flex-col ">
        {/* {Supplier.map((item, i) => (
              <div
                className="p-4 sm:w-[350px] w-[400px] flex flex-col gap-4 bg-white rounded-md  sm:min-h-[466px]"
                key={i}
              >
                <div className="flex items-center space-x-3">
                  <img src={item.icon} alt="icon" className="w-14 h-14" />
                  <h1 className="sm:text-lg text-[19px] leading-7">{item.title}</h1>
                </div>
                <span className="text-base leading-8">{item.desc}</span>
                <img src={item.img} alt="" />
              </div>
            ))} */}
      </div>

      <div className="flex justify-evenly items-center  flex-wrap sm:gap-6 gap-4 flex-col ">
        {/* {AgedCarePerson.map((item, i) => (
              <div
                className="p-4 sm:w-[350px] w-[400px] flex flex-col gap-4 bg-white rounded-md  sm:min-h-[466px]"
                key={i}
              >
                <div className="flex items-center space-x-3">
                  <img src={item.icon} alt="icon" className="w-14 h-14" />
                  <h1 className="sm:text-lg text-[19px] leading-7">{item.title}</h1>
                </div>
                <span className="text-base leading-8">{item.desc}</span>
                <img src={item.img} alt="" />
              </div>
            ))} */}
      </div>
      {/* Features you Love */}
      {/* <div className="flex flex-col  sm:gap-6 gap-4 justify-evenly ">
            <h1 className="text-[22px] font-bold text-center">Features you Love</h1>
            <div className="flex justify-evenly items-center flex-wrap sm:gap-0 gap-4 ">
              {features.map((item, i) => (
                <div
                  key={i}
                  className="flex gap-8 sm:w-[600px] sm:h-60 overflow-hidden sm:justify-evenly justify-center items-center sm:items-start flex-col sm:flex-row"
                >
                  <img src={item.img} alt="img" className="sm:w-2/4" />
                  <div>
                    <h4 className="text-[19px] leading-7">{item.title}</h4>
                    <span className="text-base leading-8 ">{item.desc}</span>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

      {/* <div className="flex flex-col  sm:gap-6 gap-4 justify-evenly ">
            <div className="text-center ">
              <h1 className="text-[22px] font-bold text-[#333333]">How we Build</h1>
              <span className="text-base leading-8 ">
                Through a combination of intelligent process automation, artificial intelligence, and automation and
                orchestration, Care Commerce develop an automated workflow which cuts significant time, money and hassle
                out of being involved in claims processes.
              </span>
            </div>
            <div className="flex flex-wrap justify-evenly gap-6">
              {HowWeBuild.map((item, i) => (
                <div className="flex flex-col gap-8 sm:w-60 rounded-sm shadow-lg " key={i}>
                  <img src={item.img} alt="" />
                  <div className="p-3">
                    <h1 className="text-[19px]  leading-7">{item.title}</h1>
                    <span className="text-base leading-8 ">{item.desc}</span>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
    </section>
  );
};

export default Sec4;
