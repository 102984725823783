import React from "react";
// import { useState } from "react";
import delivery from "../../../../assets/ind/sec4/delivery.svg";
import payment from "../../../../assets/ind/sec4/payment.svg";
import mblSeller from "../../../../assets/ind/sec4/mblSeller.svg";
import paymentSettlement from "../../../../assets/ind/sec4/paymentSettlement.svg";
import seller from "../../../../assets/ind/sec4/seller.svg";

import "./Section4.css";
import styles from "../../../../styles/index";

const Section4 = () => {
  // const [open, setOpen] = useState(false);
  return (
    // <section className={`${styles.xPaddings}`}>
    //   <div className={`${styles.innerWidth} mx-auto bg-[#f9fafb]`}>
    //     <div className="wrapper">
    //       <div className="top">
    //         <h2>Network Participants</h2>
    //         <span>
    //           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut arcu purus, condimentum fermentum lacus sit
    //           amet, interdum ullamcorper sem.
    //         </span>
    //       </div>
    //       <div className="middle">
    //         <button
    //           onClick={() => {
    //             setOpen(false);
    //           }}
    //           className={`button ${!open && "sec4_selected"}`}
    //         >
    //           Network Participants
    //         </button>
    //         <button
    //           onClick={() => {
    //             setOpen(true);
    //           }}
    //           className={`button ${open && "sec4_selected"}`}
    //         >
    //           Logistics
    //         </button>
    //       </div>
    //       <div className="bottom">
    //         {!open && <img src={Network} alt="network participants" />}
    //         {open && <img src={Logistics} alt="logistics" />}
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section className={`sm:py-6 py-6  flex flex-col items-center  ${styles.xPaddings} bg-[#F9FAFB] `}>
      <div className={`${styles.innerWidth} mx-auto flex justify-evenly flex-col h-full`}>
        <div className="text-center sm:mb-8 mb-6 space-y-4 ">
          <h1 className="sm:text-xl  text-[22px] leading-8 font-bold">Payment Experience</h1>
          <p className="sm:text-base text-[16px] leading-8">
            Say Goodbye to Payment Headaches and Hello to Seamless Transactions! As a seller, you deserve a payment
            experience that is fast, secure, and hassle-free.
          </p>
        </div>

        <div className="">
          <div className="justify-evenly hidden md:flex w-full ">
            <img src={payment} alt="" className="w-[27%]" />
            <img src={seller} alt="" className="w-[50%]" />
            <img src={delivery} alt="" className="w-[25%]" />
          </div>
          <div className="mt-3 justify-evenly hidden md:flex w-full ">
            <img src={paymentSettlement} alt="" className="" />
          </div>
        </div>

        <div className="flex flex-col md:hidden gap-4 items-center px-3">
          {[payment, seller, mblSeller, paymentSettlement].map((item, i) => (
            <img src={item} alt="" key={i} className={`object-contain w-full`} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section4;
