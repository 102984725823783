import { Helmet } from "react-helmet";

import { seo } from "../../utils/seo";

import { Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8 } from "./components/index";

function CarePage() {
  return (
    <main>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 page="0" />
      <Section6 page="1" />
      <Section6 page="2" />
      <Section7 />
      <Section8 />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{seo.index.title}</title>
        <link rel="canonical" href="https://www.carecommerce.in/" />

        <meta name="description" content={seo.index.description} />
      </Helmet>
    </main>
  );
}
export default CarePage;
