import React from "react";
import styles from "../../../../styles";
import { sec2data } from "./data";

const Sec2 = ({ path }) => {
  let data;
  if (path === "claims") {
    data = sec2data.claims;
  } else if (path === "invoice") {
    data = sec2data.invoice;
  } else {
    data = sec2data.payment;
  }
  return (
    <section
      className={` ${styles.xPaddings} xl:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)]  `}
    >
      <div className={`${styles.innerWidth}  mx-auto sm:py-6 py-6 flex items-center sm:h-full`}>
        <div className="flex  flex-col lg:flex-row">
          <div className="flex-1 space-y-5 p-6">
            <div className="flex items-center gap-3">
              <img src={data.icon1} alt="" className="w-6 h-6" />
              <h1 className="text-[19px] font-bold">Technology</h1>
            </div>
            <p className="text-base leading-7 text-justify">{data.p1}</p>
            <p className="text-base leading-7 text-justify">{data.p2}</p>

            {path === "payment" && (
              <>
                <p className="text-base leading-7 text-justify">
                  Payment Mode, We make a payment via API and fully automated, Robust and agile, Validate Payment
                  Details
                </p>
                {/* <h5 className="font-semibold">
                  Hint :
                  <span className=" font-normal text-base leading-7 text-justify">
                    {" "}
                    Mircoservice API Driven Multi Tenent
                  </span>
                </h5> */}
              </>
            )}
          </div>
          <div className="flex-1 space-y-5 p-6  ">
            <div className="flex items-center gap-3">
              <img src={data.icon2} alt="" className="w-6 h-6" />
              <h1 className="text-[19px] font-bold">Use Case</h1>
            </div>
            <p className="text-base leading-7 text-justify">{data.p3}</p>
            <p className="text-base leading-7 text-justify">{data.p4}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sec2;
