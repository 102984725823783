export const requirements = [
  "Ability to work well in a team with strong problem-solving skills",
  "An understanding of basic E-commerce applications",
  "Having a basic understanding of programming",
  "Familiarity with Basic front-end technologies such as HTML, CSS, JavaScript and React",
  "Familiarity with Basic back-end technologies such as Node.js, PHP, or Python",
  "Basic understanding of database management and SQL",
  "Basic Understanding of Git and Docker",
  "Make sure you have a good laptop or desktop computer",
];

export const details = [
  {
    key: "Schedule",
    value: "Full-Time",
  },
  {
    key: "Type of internship",
    value: "Paid",
  },
  {
    key: "Openings",
    value: 2,
  },
  {
    key: "Location",
    value: "Chennai",
  },
  {
    key: "Perks",
    value: "Stipend, Certificate",
  },
  {
    key: "Duration",
    value: "6 Months",
  },
  {
    key: "Work mode",
    value: "Offline/ Hybrid Mode",
  },
];
