import React from "react";
// import { Tabs } from "antd";
// import flowchart from "../../assets/Logos/footer_logo/flowchart.svg";
// import flowchart2 from "../../../../assets/footer_logo/flowchart2.svg";
// import flowchart3 from "../../../../assets/footer_logo/flowchart3.svg";
import buyer from "../../../../assets/ind/sec3/main.svg";

import "../../../../styles/App.css";
import "./Section3.css";
// import { Link } from "react-router-dom";
import styles from "../../../../styles/index";

function Section3() {
  // const data = [
  //   {
  //     img: flowchart2,
  //     title: "Invoice",
  //     subtitle: "AI Invoice Processing",
  //     desc: "Save times in analysing, validating the invoices and reduces the risk of duplication.",
  //     link: "",
  //   },
  //   {
  //     img: flowchart3,
  //     title: "Claims",
  //     subtitle: "Intelligent Claim Processing",
  //     desc: "Lower your costs, streamline settlements and reduce processing timelines while keeping customers engaged and well informed.",
  //     link: "",
  //   },
  //   {
  //     img: flowchart3,
  //     title: "Payment",
  //     subtitle: "Automated Payment Processing",
  //     desc: "Faster your payment processing and cycle times, Reduced your transaction Cost, eliminates the chance of duplicate payments and input errors.",
  //     link: "",
  //   },
  // ];

  return (
    // <section className={`${styles.xPaddings}`}>
    //   <div className={`${styles.innerWidth} mx-auto py-6 `}>
    //     {/* <div className="p-6"> */}
    //     <div className="text-center ">
    //       <h1 className="text-xl font-bold">Buyer Experience</h1>
    //       <span className="text-base">
    //         Our technology automates and inegrate end to end claim settlement process. Empowering the Aged Care Industry
    //       </span>
    //     </div>
    //     <div className="Tabs">
    //       <Tabs defaultActiveKey="1" centered>
    //         <Tabs.TabPane tab="Buyer" key="1">
    //           <div className="Tab">
    //             {/* <div className="left">
    //               <h3 className="left-title">Buyer Exp</h3>
    //               <p className="left-desc">
    //                 {" "}
    //                 Lorem Ipsum is simply dummy text of
    //                 <br />
    //                 the printing and typesetting industry
    //               </p>
    //               <Link to={`/Buyer-Experience`}>
    //                 <button className="left-button">View</button>
    //               </Link>
    //             </div> */}
    //             <img className="buyer" src={buyer} alt="logo" />
    //           </div>
    //         </Tabs.TabPane>
    //         <Tabs.TabPane tab="Payment" key="2">
    //           <div className="Tab">
    //             <div className="left">
    //               <h3 className="left-title">Payment</h3>
    //               <p className="left-desc">
    //                 {" "}
    //                 Lorem Ipsum is simply dummy text of
    //                 <br />
    //                 the printing and typesetting industry
    //               </p>
    //               <Link to={`/Payment`}>
    //                 <button className="left-button">View</button>
    //               </Link>
    //             </div>
    //             <img className="flowchart-logo" src={flowchart3} alt="logo" />
    //           </div>
    //         </Tabs.TabPane>
    //         <Tabs.TabPane tab="Delivery" key="3">
    //           <div className="Tab">
    //             <div className="left">
    //               <h3 className="left-title">Delivery</h3>
    //               <p className="left-desc">
    //                 {" "}
    //                 Lorem Ipsum is simply dummy text of
    //                 <br />
    //                 the printing and typesetting industry
    //               </p>
    //               <Link to={`/Delivery`}>
    //                 <button className="left-button">View</button>
    //               </Link>
    //             </div>
    //             <img className="flowchart-logo" src={flowchart3} alt="logo" />
    //           </div>
    //         </Tabs.TabPane>
    //       </Tabs>
    //     </div>
    //   </div>
    // </section>

    <section className={`sm:my-6 my-6  flex flex-col items-center  ${styles.xPaddings} sm:h-[calc(100vh-4rem)] `}>
      <div className={`${styles.innerWidth} mx-auto flex justify-evenly flex-col h-full gap-4 sm:gap-0`}>
        <div className="text-center sm:mb-8 mb-6 space-y-4">
          <h1 className="sm:text-xl  text-[22px] leading-8 font-bold">Buyer Experience</h1>
          <p className="sm:text-base text-[16px] leading-8">
            Unleash Your Potential with a Seamless Selling Experience! Join us and watch your business grow as we take
            care of everything from payment processing to end-to-end logistics,
          </p>
        </div>

        <div>
          <img src={buyer} alt="" />
        </div>
      </div>
    </section>
  );
}
export default Section3;
