import claims from "../../../../assets/aus/claims/main.svg";
import invoice from "../../../../assets/aus/invoice/main.svg";
import payment from "../../../../assets/aus/payment/main.svg";
import settings from "../../../../assets/aus/small_icons/settings.svg";
import technology from "../../../../assets/aus/small_icons/technology.svg";
import usecase from "../../../../assets/aus/small_icons/usecase.svg";

import Invoice from "../../../../assets/aus/sec2/Invoice1.svg";
import Claims from "../../../../assets/aus/sec2/Claim1.svg";
import Payment from "../../../../assets/aus/sec2/Payment1.svg";

import value from "../../../../assets/aus/small_icons/value.svg";
import downarrow from "../../../../assets/aus/small_icons/downarrow.svg";

import idea from "../../../../assets/aus/small_icons/idea.svg";

export const sec1data = {
  claims: {
    icon: settings,
    title: "What is our Claim Processing",
    p1: "With quick operating teams and a digital claims platform that can streamline the entire claim settlement process, Care Commerce offers a Claims as a Service (CaaS) solution to the aged care industry. Colakin Care will take the important information from the invoice that the provider gave, such as the names of the clients and the invoice number, turn it into a claim form, and send the claim form on behalf of the aged care providers to B2G (Aged Care Web Service).",
    p2: "Colakin Care will be notified after the payment has been distributed by the government to the providers. Through Colakin Care, providers can track the actual situation of the entire claim process. Depending on the aged care business, B2G (Aged Care Web Service) completes the claim by the end of the week or the month. New claims will be reported to the government.",
    img: claims,
  },
  invoice: {
    icon: settings,
    title: "What is our Invoice Processing",
    p1: "Our AI system is being programmed to read and comprehend invoices at a human level while also performing the same tasks several times faster than a person. A few  seconds or minutes of computer processing time can replace hundreds of hours of  laborious effort thanks to the neural network's ability to recognise and process  invoices. OCR extracts data from documents intelligently when used for scanning  and processing invoices.",
    p2: "At a human level, our AI technology does read and comprehend an invoice. It is programmed to complete the same task a great deal faster than a person. The neural network can process invoices and recognise them, cutting the processing time down from hundreds of hours of laborious effort to only a few seconds or minutes. OCR intelligently extracts data from documents used for scanning and processing invoices.",
    img: invoice,
  },
  payment: {
    icon: settings,
    title: "What is our Payment Processing",
    p1: "The automated payment processing does involve both the approval of payments as well as the payments actual transfer. Once the claims are submitted, care commerce does automatically check the claim amount, and if the claim amount is less than the service amount, it sends a notification to the client to pay the rest of the amount on behalf of the provider.",
    p2: "Care commerce will verify and validate the payment account after receiving client guidelines established by the providers in the systems. Through Colakin Care, payments and send payments to suppliers and providers in accordance with the suppliers and providers can view the payment information, and we make the payment through API and is fully automated, where the money is directly sent to the bank account. The frequency of receiving payment statements is up to the provider.",
    img: payment,
  },
};

export const sec2data = {
  claims: {
    icon1: technology,
    p1: "Our robotic process automation (RPA) is capable of transforming the claims processing industry. RPA integrates seamlessly with all applications and systems, including legacy systems, at the presentation layer. It can scale up or down completely depending on the situation and has a 100% robotic precision.",
    p2: "It's the perfect solution to increase productivity, cut costs, and boost customer satisfaction. Intelligent claim process automation integrates the entire aged care industry by integrating key stakeholders and workflows involved in the claim settlement, claim management, and claim resolution process. It connects the bank, the recipient, the provider, and the supplier to a single, centralised platform for the handling of claims processes.",
    icon2: usecase,
    p3: "Developed differently for various stakeholders, care commerce is a mobile application i.e. aged care people can book a service for them through the application. Once the service is provided to the aged care person by the supplier, Aged care supplier can upload their invoices for payment settlement and can also list the services on the care commerce application. Aged care suppliers have a variety of options for submitting invoices, including emailing them by Dropbox, email, or the system.",
    p4: "Once the invoices are uploaded, the care commerce platform helps aged care providers to fully automate the analysis and validation of invoices, claim resolution, and claim settlement processes. Through care commerce, providers can see the actual situation of the entire claim process. Aged care providers will be informed if their claim has been granted or refused by the government and will then be paid. If the claim is approved, after receiving the payment, care commerce will examine the payment account and distribute the funds to suppliers and providers in accordance with the policies established by the systems' providers. If the claim amount is less than the service amount, care commerce will automatically check the claim amount and ask the customer to pay the provider on their behalf. Aged care people have the ability to validate an invoice or reject specific invoices and services.",
  },
  invoice: {
    icon1: technology,
    p1: "When a scanned document or image file is received through email, Google Drive, or Dropbox, our optical character recognition (OCR) technology automates the data extraction from printed or written invoice. Invoices can also be handled by integrating Saleforce, Xero, MyOB, and Quickbook. After the invoices are scanned, the text is transformed into a machine-readable format so that it may be used for data processing.",
    p2: "With the help of our optical character recognition (OCR) technology, we can automatically extract data from printed or handwritten invoices from scanned documents or image files that we receive by email, Google Drive, or Dropbox. The text on the invoices is then converted into a machine-readable format for use in data processing after they have been scanned.",
    icon2: usecase,
    p3: "Developed differently for various stakeholders, care commerce is a mobile application i.e. aged care people can book a service for them through the application. Once the service is provided to the aged care person by the supplier, Aged care supplier can upload their invoices for payment settlement and can also list the services on the care commerce application. Aged care suppliers have a variety of options for submitting invoices, including emailing them by Dropbox, email, or the system.",
    p4: "Once the invoices are uploaded, the care commerce platform helps aged care providers to fully automate the analysis and validation of invoices, claim resolution, and claim settlement processes. Through care commerce, providers can see the actual situation of the entire claim process. Aged care providers will be informed if their claim has been granted or refused by the government and will then be paid. If the claim is approved, after receiving the payment, care commerce will examine the payment account and distribute the funds to suppliers and providers in accordance with the policies established by the systems' providers. If the claim amount is less than the service amount, care commerce will automatically check the claim amount and ask the customer to pay the provider on their behalf.  Aged care people have the ability to validate an invoice or reject specific invoices and services.",
  },
  payment: {
    icon1: technology,
    p1: "For the purpose of streamlining and automating the entire payment process, our automated payment processing technology interfaces with banks and the government. To provide a more seamless, automated wire-payments procedure, the platform gathers and standardises data items including payment amount, beneficiary, and account details using cutting-edge machine-learning techniques.",
    p2: "With the help of Multi tenant and Microservice which develops a single application with the help of small services, our software is API driven.",
    icon2: usecase,
    p3: "Developed differently for various stakeholders, care commerce is a mobile application i.e. aged care people can book a service for them through the application. Once the service is provided to the aged care person by the supplier, Aged care supplier can upload their invoices for payment settlement and can also list the services on the care commerce application. Aged care suppliers have a variety of options for submitting invoices, including emailing them by Dropbox, email, or the system.",
    p4: "Once the invoices are uploaded, the care commerce platform helps aged care providers to fully automate the analysis and validation of invoices, claim resolution, and claim settlement processes. Through care commerce, providers can see the actual situation of the entire claim process. Aged care providers will be informed if their claim has been granted or refused by the government and will then be paid. If the claim is approved, after receiving the payment, care commerce will examine the payment account and distribute the funds to suppliers and providers in accordance with the policies established by the systems' providers. If the claim amount is less than the service amount, care commerce will automatically check the claim amount and ask the customer to pay the provider on their behalf.Aged care people have the ability to validate an invoice or reject specific invoices and services.",
  },
};

export const sec3data = {
  icon1: value,
  icon2: downarrow,
  claims: [
    "Boost process effectiveness by making decisions more quickly, which will boost effectiveness. Our technologies automate the consumption of content, such as document ingestion, optical character recognition, and signature verification tools. Thus, with the combination of these technologies, all procedures are completed in a matter of seconds.",
    "Automation in claims processing lessens the impact of mistakes made by people. The mechanisms for tracking and monitoring are improved by this. The transparency of the claim procedure is increased with proper tracking. With the use of these findings, aged people's service providers can decide how to give them the best care possible.",
    "This data is openly provided by the intelligent claims processing system. A flowchart is provided to clients so they can follow the claim procedure. Additionally, the dashboard provides them with up-to-the-minute information. This raises consumer awareness and involvement.",
    "Measure claim processing timeframes and improve them up. Helps to have a clear tracking of how the claims are being done and makes the process very easier.",
    "Enhanced internal and external communication as well as better claim control.",
    "Automate and manage all touchpoints, including appointment scheduling, case openings, and assignment management.",
    "Aged care providers will be informed if their claim has been granted or refused by the government and will then be paid.",
  ],
  invoice: [
    "Usually a business works with numerous vendors and numerous invoices on a daily basis. Because invoices might be received in a variety of formats, including hard copies,scanned PDFs, word processing files, etc., the issue is made tougher. Managing these many invoice formats, received at various times and frequently by different employees,becomes difficult. Missed invoices result in penalties for late payments, damaged vendor relationships, operational delays, and audit-related problems.",
    "Important information on an invoice includes corporate IDs, tax data, the service or product that was provided, the price, the delivery status, etc. When invoices come in different forms, it is difficult, if not impossible, to verify that they have all the necessary information. Some errors that are simple to overlook include utilising the incorrect client's name, failing to accurately itemise products and services, and failing to disclose discounts or penalty costs.",
    "Especially if there are numerous stakeholders participating in the process, it is frequently impossible to determine the present status of an invoice inside its processing schema. Where is it? Who gave their approval? What more is required? When will the money be transferred? Due to a lack of transparency, these queries frequently surface.",
    "A business may receive numerous invoices each day from various vendors. The invoice processing team places each invoice in the routing queue after performing a preliminary inspection. Routing an invoice incorrectly is a possibility for even the most proficient specialist. An incorrect routing of an invoice causes it to go through extra procedures, wasting time and labour.",
  ],

  payment: [
    "Through Colakin Care, providers can receive payments from customers and check payment information, including the timing and amount of the payment. For example, when there is a situation of a client paying the money to the provider for the service that is provided, it can be done very easily through this software.",
    "Payment information is recorded in the payment statement, including monthly payments made or the total amount of time a client or supplier has been in operation. This helps in better understanding how the payment mode is done and allows you to go back and check the transactions in future.",
    "Colakin Care will check the payment account after receiving customer payments and send supplier payments. Through Colakin Care, suppliers can view the payment information.",
    "The frequency at which suppliers receive payment statements is up to them. Payment data are detailed in the payment statement, such as the total number of hours has worked.",
    "Colakin Care will automatically check the claim amount and request payment from clients behalf to providers if the claim amount is less than service amount.",
    "Reduce costs with the help of integrated data analytics to make decisions with higher profit margins.",
  ],
};

export const sec4data = {
  icon1: idea,
  claims: [
    "Claims are delayed as a result of manual operations taking a lengthy time.",
    "Customer experiences are disrupted when claims are manually processed with several touchpoints and vast quantities of data.",
    "Manual claim process makes the tasks more repetitive and often.",
    "Analysing and validating all the invoices manually does affect the working power of the employees, thus resulting in delayed work.",
  ],
  invoice: [
    "Increase process efficiency by making decisions more quickly, which will boost effectiveness. Our technologies automate the consumption of content, such as document ingestion, optical character recognition, and signature verification tools. Thus, with the combination of these technologies, all procedures are completed in a matter of seconds.",
    "Automation of invoice processing eliminates the effects of human error. The monitoring and tracking procedures are enhanced as a result. Correct tracking increases the transparency of the claim process as a whole. Aged care providers can make well-informed choices on how to offer the best services to clients.",
    "By integrating hyper-automation technologies with newly developed operational procedures, our system will reduce operational costs by 30%. In a digital-first world, the move towards hyper-automation will be a crucial component for businesses to achieve operational excellence and, as a result, cost savings.",
    "By adding checks throughout the procedure and enhancing visibility, automating invoice approval workflows helps eradicate fraud. During automation, irregularities can be automatically identified. Internal and external frauds, as well as the resulting losses, can be avoided using electronic signature verification.",
  ],
  payment: [
    "There will be less transparency when the things are manually processed. Stakeholders don't have exact information about the situation of the claim.",
    "Communicating with different stakeholders on a single issue takes a lot of time and effort.",
    "Talking and convincing the clients for the excess amount is an issue.",
    "When a situation arises to check the transactions that are done in the past, it does consume a lot of time.",
  ],
};

export const sec5data = [
  {
    img: Invoice,
    title: "invoice",
    subtitle: "Cutting-edge Invoice Processing",
    desc: "Reduces the likelihood of duplication and improves the analysis, extracting critical items, dates and validation of the invoices.",
    link: "",
  },
  {
    img: Claims,
    title: "claims",
    subtitle: "Processing Claims with Intelligence",
    desc: "Keeping consumers involved and updated, simplifying, validating and shortening claiming processing and times.",
    link: "",
  },
  {
    img: Payment,
    title: "payment",
    subtitle: "Automatic Processing of Payments",
    desc: "Faster payment processing and cycle times decreased transaction costs and a zero-percent possibility of double payments.",
    link: "",
  },
];
