import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CarePageIndia from "./India/CarePage";
import CarePageAus from "./Aus/HomePage";
import { CountryContext } from "../context/ChangeCountryContext";
import Career from "./careers/Career";
// import Invoice from "./Aus/Invoice";
import Claims from "./Aus/Claims/Claims";
// import Payment from "./Aus/Payment";

// import Sec3Page from "./sec3Page/Sec3Page";
// import ReadmoreOne from "./components/Pages/Page2/ReadmorePages/ReadmoreOne";
// import ReadmoreThree from "./components/Pages/Page2/ReadmorePages/ReadmoreThree";
// import ReadmoreTwo from "./components/Pages/Page2/ReadmorePages/ReadmoreTwo";
function Layout() {
  const { country } = useContext(CountryContext);

  return (
    <div>
      {/* <div className="lg:mx-16 overflow-hidden md:mx-12 sm:mx-7 mx-5 "> */}
      <Router forceRefresh={true}>
        <Navbar />
        <Routes>
          <Route path="/" element={country === "India" ? <CarePageIndia /> : <CarePageAus />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/invoice" element={<Claims />} />
          <Route path="/claims" element={<Claims />} />
          <Route path="/payment" element={<Claims />} />
          {/* <Route path="/:id" element={<Sec3Page />} /> */}

          {/* <Route path="/australia" element={<HomePage />} /> */}
          {/* <Route path="/readmoreone" element={<ReadmoreOne />} />
          <Route path="/readmoretwo" element={<ReadmoreTwo />} />
        <Route path="/readmorethree" element={<ReadmoreThree />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default Layout;
