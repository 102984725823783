import React from "react";
import { Sec1, Sec2, GradientTop, Sec3, Sec4, Sec5 } from "./components";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { seo } from "../../../utils/seo";

const Claims = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/").pop();

  let data;

  if (pathname === "claims") {
    data = seo.Claims;
  } else if (pathname === "invoice") {
    data = seo.Invoice;
  } else {
    data = seo.Payment;
  }
  return (
    <main>
      <GradientTop />
      <Sec1 path={pathname} />
      <Sec2 path={pathname} />
      <Sec3 path={pathname} />
      <Sec4 path={pathname} />
      <Sec5 path={pathname} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.title}</title>
        <link rel="canonical" href={`https://www.carecommerce.in/${pathname}`} />
        <meta name="description" content={data.description} />
      </Helmet>
    </main>
  );
};

export default Claims;
