import React from "react";
import styles from "../../../../styles";
import { sec5data } from "./data";
import { Link } from "react-router-dom";

const Sec5 = ({ path }) => {
  return (
    <section className={`bg-[#F9FAFB] ${styles.xPaddings}  `}>
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-10 py-6  sm:h-full  flex flex-col items-center justify-evenly gap-6 sm:gap-3  md:gap-6`}
      >
        <h1 className="text-[19px] font-bold">eCommerce. Know more. Do more</h1>
        <div className="flex flex-col lg:flex-row items-center justify-evenly gap-6 ">
          {sec5data.map((item, i) => (
            <div
              className={`flex flex-1  p-4 justify-evenly gap-4 flex-col sm:flex-row items-center bg-white shadow-2xl rounded-md ${
                item.title === path && "hidden"
              }`}
              key={i}
            >
              <img src={item.img} alt="thumb" className="max-h-52 object-contain " />
              <div className="flex flex-col sm:gap-4 gap-4 items-start ">
                <div className="flex flex-col items-start ">
                  <h3 className="text-[18px] sm:text-[20px] leading-7 font-semibold capitalize">{item.title}</h3>
                  <span className="text-[15px]  text-[#999999]  ">{item.subtitle}</span>
                </div>
                <span className=" text-base leading-6 ">{item.desc}</span>
                <Link to={`/${item.title}`}>
                  <span className="text-[#6C63FF] cursor-pointer">Read more... {"     "}&#8594;</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec5;
