import React from "react";
import main1 from "../../../assets/ind/sec1/main1.svg";
import main2 from "../../../assets/ind/sec1/main2.svg";
import mobileMain from "../../../assets/ind/sec1/mobileMain.svg";
import styles from "../../../styles";
import { Imgdata } from "./data";

const Section1 = () => {
  return (
    // <section className={`${styles.xPaddings} mt-16 px-24`}>
    //   <div className={`${styles.innerWidth} mx-auto `}>
    //     <div className="care-content">
    //       <div>
    //         <h1 className="content-head">
    //           Streamline the <br />
    //           Through the Intelligent Automation.
    //         </h1>
    //         <p className="content">
    //           Leaveragin modern technologies to Capture and accelerate document processing timelines for claims
    //           management, settlement and resolution.{" "}
    //         </p>
    //       </div>

    //       <img className="care-logo" src={care} alt="logo" />
    //     </div>

    //     <div className="retail-menu-align">
    //       <div className="retail-container">
    //         <img className="retail-logo" src={retail} alt="logo" />
    //         <p className="retail-head">Retail</p>
    //       </div>
    //       <div className="retail-container">
    //         <img className="retail-logo" src={food} alt="logo" />
    //         <p className="retail-head">Food</p>
    //       </div>
    //       <div className="retail-container">
    //         <img className="retail-logo" src={pharmacy} alt="logo" />
    //         <p className="retail-head">Pharmacy</p>
    //       </div>
    //       <div className="retail-container">
    //         <img className="retail-logo" src={clothing} alt="logo" />
    //         <p className="retail-head">Clothing</p>
    //       </div>
    //       <div className="retail-container">
    //         <img className="retail-logo" src={logistic} alt="logo" />
    //         <p className="retail-head">Logistic</p>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section
      className={`${styles.xPaddings} mt-16 sm:mt-16 sm:h-[calc(100vh-4rem)] max-h-[calc(100vh-4rem)] sm:max-h-max snap-start`}
    >
      <div
        className={`${styles.innerWidth} mx-auto flex flex-col items-center sm:items-start sm:flex-col justify-evenly sm:justify-around  lg:h-full   py-6`}
      >
        {/* <div className="flex flex-col items-center xl:items-start  xl:flex-row xl:justify-evenly p-2 "> */}
        <div className="flex flex-col gap-20 ]">
          <div className=" flex flex-col  sm:justify-start sm:gap-6">
            {/* <div className="w-[60%]"> */}
            <h1 className="lg:text-[35px] lg:leading-[50px] font-bold sm:text-[30px] text-[26px] hidden sm:block ">
              Sell Smarter - List, Sell, Succeed!
            </h1>
            <h1 className="font-bold  text-[22px] sm:hidden  text-center leading-8 ">Empower Your Business</h1>
            <p className="sm:text-[21px] leading-9 text-[17px] hidden sm:inline w-[60%] ">
              Become a smarter seller and sell directly to leading buyers across the country, without worrying about
              about logistics and payment processing.
            </p>
          </div>
          {/* <img src={sec1Img2} alt="" className="h-fit w-[20vw] hidden sm:inline" /> */}
        </div>
        {/* <div className="absolute right-0 bottom-[18%]"> */}
        <div className="flex md:items-start xl:items-center mt-4 xl:mt-0 xl:gap-4 gap-0 flex-col xl:flex-row">
          {/* <div className="flex items-center "> */}
          {/* <img src={sec1Img} alt="logo" className="xl:w-[78%] xl:-ml-[26rem]  w-full hidden sm:inline-block" /> */}
          {/* <img src={sec1Img2} alt="" className="h-fit w-[20vw] hidden sm:inline mr-5" /> */}
          <img src={main1} alt="" className="h-fit lg:w-[20vw]  w-[26vw] hidden sm:inline" />

          <img
            src={main2}
            alt="logo"
            className=" max-h-[435px] md:-mt-36 lg:-mt-40 -mt-20  xl:-mt-28  object-cover w-full hidden sm:inline-block "
          />
          {/* <img
            src={sec1Img}
            alt="logo"
            className=" flex-grow xl:w-full h-[470px] object-cover w-full hidden sm:inline-block -mr-16 -mt-6"
          /> */}
        </div>
        <div className="flex items-center">
          <img src={mobileMain} alt="logo" className="h-[60vh] sm:hidden " />
        </div>

        <div className="flex items-center gap-2 mt-6  flex-wrap  justify-evenly sm:hidden">
          {Imgdata.map((item, i) => (
            <div className="flex flex-col items-center min-w-[60px] " key={i}>
              <img src={item.img} alt="" className="w-[50px] h-[50px]" />
              <h3 className=" text-[14px]  font-semibold">{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section1;
