import React from "react";

import "../../../styles/App.css";
import styles from "../../../styles";
import { Sec2data } from "./data";

const Section2 = () => {
  return (
    // <>
    //   <div className="care-page-footer">
    //     <h1>Collect your invoice once. Empower every team.</h1>
    //     <p>
    //       Our technology automates and inegrate end to end claim settlement process.
    //       <br />
    //       Empowering the Aged Care Industry
    //     </p>

    //     <div className="footer-align">
    //       <div>
    //         <div style={{ display: "flex" }}>
    //           <div className="footer-container">
    //             <img className="retail-logo" src={service} alt="logo" />
    //           </div>
    //           <div style={{ textAlign: "initial" }}>
    //             <p className="foot-content-head">Self Service</p>
    //             <p className="foot-content">All Invoice Processing</p>
    //           </div>
    //         </div>
    //         <div></div>
    //         <p className="service-content">
    //           Save times in analysing, validating the invoices
    //           <br /> and reduces the risk of duplication.
    //         </p>
    //         <div className="read-more">Read more...</div>
    //       </div>

    //       <div>
    //         <div style={{ display: "flex" }}>
    //           <div className="footer-container">
    //             <img className="retail-logo" src={growth} alt="logo" />
    //           </div>
    //           <div style={{ textAlign: "initial" }}>
    //             <p className="foot-content-head">Growth</p>
    //             <p className="foot-content">Intelligent Claim Processing</p>
    //           </div>
    //         </div>
    //         <div></div>
    //         <p className="service-content">
    //           Lower your costs, streamline settlements and <br />
    //           reduce processing timelines while keeping
    //           <br /> customers engaged and well informed.
    //         </p>
    //         <div className="read-more">Read more...</div>
    //       </div>

    //       <div>
    //         <div style={{ display: "flex" }}>
    //           <div className="footer-container">
    //             <img className="retail-logo" src={omni_channel} alt="logo" />
    //           </div>
    //           <div style={{ textAlign: "initial" }}>
    //             <p className="foot-content-head">Omni Channel</p>
    //             <p className="foot-content">Automated Payment Processing</p>
    //           </div>
    //         </div>
    //         <div></div>
    //         <p className="service-content">
    //           Faster your payment processing and cycle times,
    //           <br /> Reduced your transaction Cost, eliminates the
    //           <br /> chance of duplicate payments and input errors.
    //         </p>
    //         <div className="read-more">Read more...</div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    // <section className={`${styles.xPaddings}`}>
    //   <div className={`${styles.innerWidth} mx-auto bg-[#F9FAFB] mt-6 py-6`}>
    //     <div className="text-center mb-14">
    //       <h1 className="text-xl font-bold">Collect your invoice once. Empower every team.</h1>
    //       <span className="text-base">Our technology automates and inegrate end to end claim settlement process</span>
    //       <p className="text-base">Empowering the Aged Care Industry</p>
    //     </div>
    //     <div className="flex justify-between items-center mx-5 md:flex-row flex-col">
    //       {data.map((item, i) => (
    //         <div className="flex flex-col h-56 justify-evenly" key={i}>
    //           <div className="flex flex-col gap-6 w-[350px]">
    //             <div className="flex space-x-4 items-center">
    //               <img src={item.img} alt="thumb" />
    //               <div>
    //                 <h3 className="text-[20px] leading-7 font-semibold">{item.title}</h3>
    //                 <span className="text-base text-[#999999] ">{item.subtitle}</span>
    //               </div>
    //             </div>
    //             <span className="text-base font-medium">{item.desc}</span>
    //           </div>
    //           <span className="flex  text-[#6C63FF] cursor-pointer items-center">Read more... {"     "}&#8594;</span>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </section>

    <section
      className={`bg-[#F9FAFB] ${styles.xPaddings} md:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)] max-h-max `}
    >
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-evenly gap-6 sm:gap-0 `}
      >
        <div className="text-center space-y-4 ">
          <h1 className="xl:text-xl font-bold text-[22px] leading-8">
            Empower Your Sales, Let Us Handle the Rest Inclusively.
          </h1>
          <p className="xl:text-base text-[16px] leading-8">
            Our platform provides you with integrated end-to-end digital sakes solutions from categlog to delivery.
          </p>
        </div>
        <div className="flex xl:justify-between items-center flex-col md:flex-row xl:flex-nowrap justify-evenly xl:gap-0 gap-6 lg:justify-evenly w-full">
          {Sec2data.map((item, i) => (
            <div
              className="flex flex-col sm:h-fit justify-evenly gap-4 last:mb-0 sm:mb-0 md:w-[30%] items-center sm:items-start"
              key={i}
            >
              <div className="flex flex-col sm:gap-4 gap-4 ">
                <div className="flex flex-col gap-3 items-center">
                  <img src={item.img} alt="thumb" className="w-56 h-60 sm:w-[250px] sm:h-[280px] object-contain " />
                </div>
                <div className="flex flex-col sm:items-start items-center">
                  <h3 className="text-[18px] sm:text-[20px] leading-7 font-semibold">{item.title}</h3>
                  <span className="text-[15px]  text-[#999999]  ">{item.subtitle}</span>
                </div>
                <span className=" text-[16px] leading-8 sm:text-base sm:leading-8 text-center sm:text-start">
                  {item.desc}
                </span>
              </div>
              {/* <span className="flex  text-[#6C63FF] cursor-pointer items-center">Read more... {"     "}&#8594;</span> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section2;
