//~sec1
import retail from "../../../assets/retail.svg";
import food from "../../../assets/food.svg";
import pharmacy from "../../../assets/pharmacy.svg";
import logistic from "../../../assets/logistic.svg";
import clothing from "../../../assets/clothing.svg";

//~Sec2
import growth from "../../../assets/ind/sec2/growth.svg";
import omniChannel from "../../../assets/ind/sec2/omniChannel.svg";
import selfService from "../../../assets/ind/sec2/selfService.svg";

//~Sec6

import {
  kotak,
  meesho,
  paytm,
  CSC,
  craftVilla,
  idfc,
  myStore,
  phonePe,
  spiceMoney,
  loadShare,
  blowhorn,
  dunzo,
  delhivery,
  ekart,
  Grab,
  indianPost,
  shiprocket,
  bizom,
  uEngage,
  growthFalcons,
  goFrugal,
  eVital,
  eSamydaay,
  digit,
} from "../../../assets/ind/sec6/index";
import { CB, PWB, SH, saas } from "../../../assets/ind/sec7";
import { BE, IDQ, PE, dba } from "../../../assets/ind/sec8";

export const Imgdata = [
  {
    title: "Retail",
    img: retail,
  },
  {
    title: "Food",
    img: food,
  },
  {
    title: "Pharmacy",
    img: pharmacy,
  },
  {
    title: "Clothing",
    img: clothing,
  },
  {
    title: "Logistic",
    img: logistic,
  },
];

export const Sec2data = [
  {
    img: growth,
    title: "Automated Onboarding Service",
    subtitle: "AI Invoice Processing",
    desc: "Save times in analysing, validating the invoices and reduces the risk of duplication.",
    link: "",
  },
  {
    img: omniChannel,
    title: "Zero Fee",
    subtitle: "Intelligent Claim Processing",
    desc: "Lower your costs, streamline settlements and reduce processing timelines while keeping customers engaged and well informed.",
    link: "",
  },
  {
    img: selfService,
    title: "Multi-Platform Approach",
    subtitle: "Automated Payment Processing",
    desc: "Faster your payment processing and cycle times, Reduced your transaction Cost, eliminates the chance of duplicate payments and input errors.",
    link: "",
  },
];

//~Sec6

export const Sec6Data = [
  {
    title: "Buyer Network Participants",
    desc: "Get discovered your products to the countless buyers using buyer apps in the Open Network.",
    sub_title: "Unleash your potential - Scale your business vigorously.",
    sub_desc:
      "With a vast and ever-expanding network of potential customers at your fingertips, your products and services can reach more people than ever before. It's now easier than ever to scale your business.",
    color: "#6C63FF",
    button_name: "Dummy Button",
    images: [kotak, meesho, paytm, CSC, craftVilla, idfc, myStore, phonePe, spiceMoney],
  },

  {
    title: "Logistics Network Participants",
    desc: "Say goodbye to the hassle of logistics and get into the ease and efficient logistics and fullfilment for your business.",
    sub_title: "Simplify your logistics - We connect you with the perfect logistics provider!",
    sub_desc:
      "We'll take care of logistics and fulfillment so you can focus on growing your business. With a large network of trusted logistics providers, we are able to provide smooth and efficient operations.",
    color: "#EF069A",
    button_name: "Dummy Button",
    images: [loadShare, blowhorn, dunzo, delhivery, ekart, Grab, indianPost, shiprocket],
  },
  {
    title: "Seller Network Participants",
    desc: "You can sell your products via a large number of seller apps who have been in the Open Network.",
    sub_title: "Streamline your selling process - We connect you with the ideal network of sellers!",
    sub_desc:
      "The ONDC Seller Network is a community of like-minded sellers who have joined forces to expand their reach and increase their sales. As a participant in the ONDC Seller Network, you will have access to a vast network of buyers and a powerful platform to showcase your products",
    color: "#01B187",
    button_name: "Dummy Button",
    images: [bizom, uEngage, growthFalcons, goFrugal, eVital, eSamydaay, digit, craftVilla, myStore],
  },
];

export const Sec7Data = [
  {
    img: saas,
    title: "Saas",
    desc: "Our SaaS-based cloud computing platform called Care Commerce gives customers access to hosted development kits, application management tools, and database tools that businesses need to create, set up, and launch software applications.",
  },
  {
    img: SH,
    title: "Self Hosted",
    desc: "Being a self-hosted platform, care commerce has open source applications that run via web hosting. Care commerce is a decentralised platform that allows users to host services as they see fit.",
  },
  {
    img: PWB,
    title: "Partner with B2G",
    desc: "Care Commerce is a B2G eCommerce platform that streamlines claim settlement by connecting to the government site for aged care services. Our platform integrates with the end-to-end Open Banking platform to efficiently authorize and send funds to aged care providers, ensuring smooth claim settlement.",
  },
  {
    img: CB,
    title: "Cloud Based",
    desc: "Care Commerce is a system of cloud-based infrastructure that provides low-cost means to deliver claim management, settlement, and resolution processes to banks, governmental organisations, aged care providers, aged care service suppliers, and elderly people.",
  },
];

export const Sec8Data = [
  {
    img: dba,
    title: "Drive Business Agility",
    desc: "The efficient claims processing system offers accessible data. Clients navigate the procedure effortlessly through a user-friendly flowchart. Real-time dashboard updates enhance client visibility and involvement.",
  },
  {
    img: BE,
    title: "Builds an Ecosystem",
    desc: "The integrated platform in the aged care sector fosters seamless connectivity and enhances user experience. Aged care providers can leverage the community for innovation and added value.",
  },
  {
    img: PE,
    title: "Process Evolution",
    desc: "Through multithreading and computational capacity, automated invoice processing tools, such as signature verification and optical character recognition (OCR), significantly reduce claim settlement processing time.",
  },
  {
    img: IDQ,
    title: "Increase Data Quality and Accuracy",
    desc: "Our data-driven intelligent automation technology eliminates the risk of human error in business operations by relying on datasets for decision-making.",
  },
];
