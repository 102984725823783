import React from "react";
import styles from "../../../styles";
import { Sec6Data } from "./data";
import Carousel from "better-react-carousel";

const Section6 = ({ page }) => {
  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        marginTop: 20,
        marginBottom: 10,
        background: Sec6Data[page].color,
        height: isActive ? "8px" : "5px",
        width: isActive ? "8px" : "5px",
        borderRadius: "50%",
      }}
    ></span>
  );

  const layout = [
    {
      breakpoint: 680,
      cols: 2,
      rows: 2,
      // gap: 10,
      loop: true,
      autoplay: 7000,
    },
    {
      breakpoint: 800,
      cols: 2,
      rows: 2,
      gap: 10,
      loop: true,
      autoplay: 7000,
    },
  ];

  return (
    <section
      className={`
      ${page % 2 !== 0 ? "bg-white" : "bg-[#F9FAFB]"} md:h-[calc(100vh-4rem)]  sm:min-h-[calc(100vh-4rem)] max-h-max ${
        styles.xPaddings
      } `}
    >
      <div className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-evenly gap-6  `}>
        <div className="text-center space-y-4">
          <h1 className="xl:text-xl font-bold text-[22px] leading-8">{Sec6Data[page].title}</h1>
          <p className="xl:text-base text-[16px] leading-8">{Sec6Data[page].desc}</p>
        </div>

        <div
          className={`w-full flex justify-evenly items-center  ${
            page % 2 === 0 ? "flex-col md:flex-row" : " flex-col md:flex-row-reverse"
          } gap-8 md:items-center`}
        >
          <div className="flex flex-col items-center  md:items-start gap-3 md:w-1/3">
            <h1 className="font-semibold text-[19px] leading-8">{Sec6Data[page].sub_title}</h1>
            <span className="text-sm text-center md:text-start">{Sec6Data[page].sub_desc}</span>
            {/* <button className={`bg-[${Sec6Data[page].color}] text-white rounded-full px-3.5 p-1 font-medium mt-1`}>
              {Sec6Data[page].button_name}
            </button> */}
          </div>

          <div
            className="shadow-2xl rounded-lg  border border-gray-200 py-4  max-h-[570px] max-w-[650px] pb-7
          "
          >
            <Carousel
              cols={3}
              rows={3}
              // gap={10}
              loop
              hideArrow="true"
              autoplay={7000}
              showDots={true}
              dotColorActive={Sec6Data[page].color}
              dot={MyDot}
              mobileBreakpoint={300}
              responsiveLayout={layout}
            >
              {Sec6Data[page]?.images?.map((item, i) => (
                <Carousel.Item className="" key={i}>
                  <img
                    src={item}
                    key={i}
                    alt="contacts"
                    className={`lg:w-[200px] lg:h-[170px] object-cover md:w-[190px] md:h-[140px] h-full w-full -mb-7 `}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
