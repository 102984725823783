import React from "react";
import styles from "../../../styles";
import { HowWeBuild } from "./data";

const Sec5 = () => {
  return (
    <section className={` ${styles.xPaddings}  min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)]  `}>
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-around gap-6 sm:gap-0 md:gap-10`}
      >
        <div className="text-center space-y-4">
          <h1 className="xl:text-xl font-bold text-[22px] leading-8">Streamline Revenue Operations</h1>
          <span className="xl:text-base text-[16px] leading-8">
            Our technology automates and inegrate end to end claim settlement process. Empowering the Aged Care Industry
          </span>
        </div>

        <div className="flex flex-wrap justify-evenly gap-6">
          {/* <div className="gap-6 grid xl:grid-cols-4  grid-cols-1 sm:grid-cols-2 "> */}
          {HowWeBuild.map((item, i) => (
            <div className="flex flex-col gap-4 sm:w-60 rounded-sm shadow-lg " key={i}>
              <img src={item.img} alt="" />
              <div className="p-3">
                <h1 className="text-[19px]  leading-7 font-semibold">{item.title}</h1>
                <span className="text-base leading-8 ">{item.desc}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec5;
