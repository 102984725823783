import React from "react";
import styles from "../../../../styles";
import { sec4data } from "./data";

const Sec4 = ({ path }) => {
  let data;
  if (path === "claims") {
    data = sec4data.claims;
  } else if (path === "invoice") {
    data = sec4data.invoice;
  } else {
    data = sec4data.payment;
  }

  // console.log(data);

  // useEffect(() => {
  //   // Attach an event listener to the document to listen for selection changes
  //   document.addEventListener("selectionchange", handleSelectionChange);

  //   // Remove the event listener on cleanup
  //   return () => {
  //     document.removeEventListener("selectionchange", handleSelectionChange);
  //   };
  // }, []);

  // function handleSelectionChange() {
  //   // Get the highlighted text
  //   const selection = window.getSelection();
  //   const highlightedText = selection.toString();

  //   // Check if any text is highlighted
  //   if (highlightedText.length > 0) {
  //     // Get the range of the highlighted text
  //     const range = selection.getRangeAt(0);

  //     // Get the start and end positions of the range
  //     const start = range.startOffset;
  //     const end = range.endOffset;

  //     // Log the highlighted text and its position
  //     console.log("Highlighted text:", highlightedText);
  //     console.log("Start position:", start);
  //     console.log("End position:", end);
  //   } else {
  //     console.log("No text is highlighted");
  //   }
  // }
  return (
    <section className={`${styles.xPaddings}`}>
      <div className={`${styles.innerWidth}  mx-auto  py-16 items-center flex flex-col justify-evenly sm:gap-6`}>
        <div className="flex items-center sm:gap-3 justify-center">
          <img src={sec4data.icon1} alt="" className="w-6 h-6" />
          <h1 className="text-[19px] font-bold text-center">Challenges of manual claim processing</h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 ">
          {data.map((item, i) => (
            <div className="flex p-3 items-center sm:items-start justify-start space-x-4 my-3">
              <div className="bg-[#01B187] rounded-full text-white h-6 w-6 p-1 flex items-center justify-center  text-sm font-semibold">
                <p>0{i + 1}</p>
              </div>
              <p className="text-base leading-6 text-justify">{item}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec4;
