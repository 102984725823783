const ChangeCountryReducer = (state, action) => {
  switch (action.type) {
    case "INDIA": {
      return {
        country: "India",
      };
    }
    case "OTHER": {
      return {
        country: !state.country,
      };
    }

    default:
      return state;
  }
};

export default ChangeCountryReducer;
