import React from "react";
import { useLocation } from "react-router-dom";

const GradientTop = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/").pop();
  return (
    <div className="gradient w-full h-24  flex items-center justify-center mt-16">
      <h1 className="text-white z-10 font-semibold text-xl capitalize">{pathname}</h1>
    </div>
  );
};

export default GradientTop;
