import React, { useContext, useState } from "react";
// import care_commerce_logo from "../assets/care_commerce_logo.svg";
// import ausLogo from "../assets/aus/ausLogo.svg";
import mobilelogo from "../assets/logo/mobile.svg";
import care_commerce_logo from "../assets/logo/web.svg";
import { Link, NavLink } from "react-router-dom";
import { CountryContext } from "../context/ChangeCountryContext";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import styles from "../styles";

function Navbar() {
  const { country } = useContext(CountryContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const logo = country !== "India" ? ausLogo : care_commerce_logo;
  const [isScrolled, setIsScrolled] = useState(false);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  // const indNav = ["Services", "Pricing", "How it works?", "Our Team", "About us", "Contact"];

  return (
    // <nav className="flex justify-between bg-transparent items-center mt-4 mb-2  ">
    <nav
      className={`${styles.xPaddings} flex items-center w-full bg-white ${
        isScrolled && "shadow-md"
      } h-16 backdrop:blur-sm z-20 fixed top-0 `}
    >
      <div className={`${styles.innerWidth} mx-auto flex  `}>
        <Link to="/">
          <img
            src={care_commerce_logo}
            alt=""
            className={`lg:w-56 md:w-56 w-40  h-11 ${care_commerce_logo && "lg:w-32 md:w-32 w-32 "} hidden sm:inline`}
          />
          <img src={mobilelogo} alt="" className="sm:hidden mt-1" />
        </Link>
        {country !== "India" ? (
          <div className="lg:flex items-center hidden gap-2.5 ml-auto transition-all">
            {["invoice", "claims", "payment"].map((item, index) => (
              <NavLink
                to={item}
                key={index}
                activeClassName="active"
                className={`lg:mr-2 p-1.5 px-3 text-base font-medium capitalize  transition duration-300  hover:bg-[#7D75FE] rounded-full hover:text-white  active:bg-[#7D75FE] "
              `}
              >
                {/* <button
                  key={index}
                  
                > */}
                {item}
                {/* </button> */}
              </NavLink>
            ))}
            {/* <button className="bg-[#8f39ff] p-2 text-white rounded-full px-3 font-medium text-sm cursor-pointer transition duration-700 ease-in-out">
              Book Free Consultation
            </button> */}
          </div>
        ) : (
          <div className="lg:flex items-center hidden gap-2.5 ml-auto transition-all">
            <a
              href="https://doc.carecommerce.in/"
              target="blank"
              className="p-1.5 px-3 text-base font-medium duration-300 hover:bg-[#7D75FE] rounded-full hover:text-white"
            >
              Documentation
            </a>
          </div>
        )}
      </div>
      <div className="lg:hidden relative ">
        <MenuOutlined
          onClick={() => setMobileMenuOpen(true)}
          className="text-md text-[#6E64FE] transition delay-700 duration-300 ease-in-out "
        />
        {mobileMenuOpen && (
          <div
            className="fixed top-0 bottom-0  right-0 z-10 w-1/2 bg-slate-100 p-6 flex justify-end items-end
            flex-col h-screen duration-700
          "
          >
            <CloseOutlined onClick={() => setMobileMenuOpen(false)} className="text-md text-[#6E64FE]" />
            {country !== "India" ? (
              <ul className="m-0 p-0 h-full w-full flex flex-col justify-start items-start">
                {["invoice", "claims", "payment"].map((item, index) => (
                  <NavLink
                    to={item}
                    key={index}
                    activeClassName="active"
                    className="m-4 p-1.5 px-3 text-base  font-medium cursor-pointer text-gray-700 ease-in-out uppercase hover:bg-[#7D75FE] rounded-full hover:text-white  active:bg-[#7D75FE]  "
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {/* <li
                      key={index}
                    > */}
                    {item}
                    {/* </li> */}
                  </NavLink>
                ))}
                {/* <button className="bg-[#8f39ff] p-2 text-white rounded-full px-3 font-medium tex-base mt-5">
                  Book Free Consultation
                </button> */}
              </ul>
            ) : (
              <div className="m-0 p-0 h-full w-full flex flex-col justify-start items-start">
                <a
                  href="https://doc.carecommerce.in/"
                  target="blank"
                  className="p-1.5 px-3 text-base font-medium duration-300 hover:bg-[#7D75FE] rounded-full hover:text-white"
                >
                  Documentation
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
export default Navbar;
