import React from "react";
import styles from "../../../styles";
import { Sec8Data } from "../../India/components/data";
import { features } from "./data";

const Sec6 = () => {
  return (
    <section
      className={`bg-[#F9FAFB] ${styles.xPaddings} xl:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)] max-h-max `}
    >
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-evenly gap-6 sm:gap-0  md:gap-4`}
      >
        <div className="text-center">
          <h1 className="xl:text-xl font-bold text-[22px] leading-8">Streamline Revenue Operations</h1>
          <span className="xl:text-base text-[16px] leading-8">
            Our technology automates and inegrate end to end claim settlement process. Empowering the Aged Care Industry
          </span>
        </div>

        <div className="flex justify-evenly items-center flex-wrap  gap-8 ">
          {features.map((item, i) => (
            <div
              key={i}
              className="bg-white shadow-md rounded-lg p-4 flex gap-8 sm:w-[500px] sm:h-60 overflow-hidden sm:justify-evenly justify-center items-center sm:items-center flex-col sm:flex-row"
            >
              <img src={item.img} alt="img" className="object-contain w-2/5 h-4/5" />
              <div>
                <h4 className="text-[18px] leading-7 mb-2 font-semibold">{item.title}</h4>
                <p className="text-base leading-7  ">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec6;
