import React from "react";
import ApprovalFlow from "../../../assets/aus/sec3/ApprovalFlow.svg";
import ApprovalFlow1 from "../../../assets/aus/sec3/ApprovalFlow1.svg";
import Invoice from "../../../assets/aus/sec3/Invoice.svg";
import Invoice1 from "../../../assets/aus/sec3/Invoice1.svg";
import Supplier from "../../../assets/aus/sec3/Supplier.svg";
import Payment from "../../../assets/aus/sec3/Payment.svg";
import Payment1 from "../../../assets/aus/sec3/Payment1.svg";
// import Payment2 from "../../../assets/aus/sec3/payment.png";
import Dashboard from "../../../assets/aus/sec3/Dashboard.svg";
import Dashboard1 from "../../../assets/aus/sec3/Dashboard1.svg";
// import Dashboard1 from "../../../assets/aus/sec3/dashboard.png";
import styles from "../../../styles";

const Sec3 = () => {
  return (
    // <section className="sm:my-16 my-6  flex flex-col items-center">
    <section className={`sm:my-6 my-6  flex flex-col items-center  ${styles.xPaddings} `}>
      <div className={`${styles.innerWidth} mx-auto`}>
        <div className="text-center sm:mb-8 mb-6 ">
          <h1 className="sm:text-xl  text-[22px] leading-8 font-bold">
            Smarter. Automated. Centralized
            {/* An Unique digital solution to support the complete payment process */}
          </h1>
          <span className="sm:text-base text-[16px] leading-8">
            Our technology automates and inegrate end to end claim settlement process.Empowering the Aged Care Industry
          </span>
        </div>
        {/* <div className="flex  flex-col sm:flex-row  justify-center sm:justify-evenly items-center flex-wrap"> */}
        <div className="sm:flex lg:flex-row lg:items-end flex-col  items-center hidden ">
          <div className="flex  flex-col sm:gap-5  items-center">
            <div className="sm:p-4 p-6 ">
              {/* <div className="sm:p-6 p-6 bg-blue-900 sm:bg-transparent"> */}
              <img src={Supplier} alt="" />
            </div>
            <div className="p-4 pb-0 ">
              {/* <div className="p-4 pb-0 bg-pink-900 sm:bg-transparent"> */}
              <img src={Invoice} alt="" />
            </div>
          </div>
          <div className=" flex flex-col sm:gap-5">
            <div className="flex lg:flex-row flex-col lg:items-baseline items-center ">
              <div className="">
                {/* <div className="bg-blue-900 sm:bg-transparent"> */}
                <img src={ApprovalFlow} alt="" />
                {/* <div className="bg-green-900 w-full sm:w-fit p-4 sm:inline flex justify-center sm:bg-transparent"> */}
              </div>
              {/* <div className="p-3 sm:p-0 bg-green-900 sm:bg-transparent "> */}
              <div className="p-3 sm:p-0  ">
                <img src={Payment} alt="" />
              </div>
            </div>
            <div className="sm:p-6 sm:pb-0 p-6 ">
              {/* <div className="sm:p-6 p-6 bg-blue-700 sm:bg-transparent"> */}
              <img src={Dashboard} alt="" />
            </div>
          </div>
          {/* <div className="bg-pink-900 w-full sm:w-fit p-4 sm:inline flex justify-center sm:bg-transparent"> */}
        </div>
        {/* </div> */}

        <div className="flex flex-col sm:hidden gap-4 items-center px-3">
          {[Supplier, Invoice1, ApprovalFlow1, Payment1, Dashboard1].map((item, i) => (
            <img src={item} alt="" key={i} className={`object-contain w-full ${i === 2 && "-my-3"}`} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec3;
