import { CaretDownOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CountryContext } from "../context/ChangeCountryContext";
import India from "../assets/footer/india-flag-icon.png";
import Australia from "../assets/footer/ausRect.png";
import linkedIn from "../assets/common/linkedin.svg";
import connect from "../assets/common/connect.svg";
import { sec2data } from "../Pages/Aus/components/data";

const Footer = () => {
  const { dispatch, country } = useContext(CountryContext);
  // const [openSelectCountry, setopenSelectCountry] = useState(false);
  // const countryName = country === "India" ? "India" : "Australia";
  // const countryLogo = country === "India" ? India : Australia;

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath, country]);

  // const data = [
  //   {
  //     title: "CARE COMMERCE",
  //     items: [
  //       { name: "Home", link: "/" },
  //       { name: "Services", link: "/" },
  //       { name: "Pricing", link: "/" },
  //       { name: "How It Works", link: "/" },
  //       { name: "Our Team", link: "/" },
  //       { name: "About", link: "/" },
  //       { name: "Contact", link: "/" },
  //       { name: "Careers", link: "/careers" },
  //     ],
  //   },
  //   {
  //     title: "RESOURCES",
  //     items: [
  //       { name: "FAQs", link: "/" },
  //       { name: "Help Guides", link: "/" },
  //       { name: "Forms", link: "/" },
  //       { name: "Self Assessment Tool", link: "/" },
  //       { name: "Book A Consultation", link: "/" },
  //       { name: "Feedback & Complaints", link: "/" },
  //     ],
  //   },
  // ];

  // const contact = {
  //   title: "CONTACT",
  //   items: [
  //     {
  //       key: "Call:",
  //       value: (
  //         <a href="tel:1300 655 688" className="text-inherit hover:text-inherit">
  //           1300 655 688
  //         </a>
  //       ),
  //     },
  //     {
  //       key: "Working Days:",
  //       value: "Monday - Friday",
  //     },
  //     {
  //       key: "Time:",
  //       value: "9am-5pm (AEST)",
  //     },
  //   ],
  // };

  const onChange = (checked) => {
    checked ? dispatch({ type: "OTHER" }) : dispatch({ type: "INDIA" });
    // setopenSelectCountry(false);
  };

  return (
    // <footer
    //   // className="flex flex-col items-center justify-center sm:p-10 p-5 md:px-24 mt-10"
    //   className={`${styles.xPaddings} p-5 gradient`}
    // >
    //   <div className={`${styles.innerWidth} mx-auto flex flex-col items-center justify-center  mt-10`}>
    //     <div className="flex justify-between items-center bg-white rounded-xl lg:rounded-full sm:p-3 p-3 sm:pl-8   w-full">
    //       <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-3">
    //         <h3 className="text-[#01B187]  text-[20px]   sm:leading-7 font-bold ">Ready to Join?</h3>
    //         <h6 className=" sm:text-[20px] text-base leading-7 font-normal text-center ">
    //           Contact us today and we’ll guide and support you through the process.
    //         </h6>
    //       </div>
    //       <a href="https://sprw.io/stt-eHYuW2JYVkg6m6jh3JDHVJ">
    //         <button className="bg-[#01B187] text-white p-2.5 md:px-4 rounded-full text-base font-semibold hover:bg-teal-700 hidden xl:inline">
    //           Book Free Consulation
    //         </button>
    //       </a>
    //     </div>
    //     <div className="flex text-white mt-12 sm:justify-evenly w-full justify-between gap-4 sm:gap-7 flex-wrap">
    //       {data.map((item, i) => (
    //         <div className=" flex flex-col justify-between sm:mb-0 mb-10 " key={i}>
    //           <h5 className="text-white sm:text-[20px] text-sm sm:leading-7 font-semibold mb-3">{item.title}</h5>
    //           {item.items.map((listItem, i) => (
    //             <li className="list-none sm:text-base text-sm mb-2 cursor-pointer" key={i}>
    //               <Link to={listItem.link}>
    //                 {" "}
    //                 &#8594; &nbsp;&nbsp;
    //                 {listItem.name}
    //               </Link>
    //             </li>
    //           ))}
    //         </div>
    //       ))}
    //       <div className=" flex justify-between flex-wrap ">
    //         <div>
    //           <h5 className="text-white sm:text-[20px] text-sm leading-7 font-semibold mb-3">{contact.title}</h5>
    //           {contact.items.map((listItem, i) => (
    //             <div key={i}>
    //               <p className="list-none text-base font-bold mb-2"> {listItem.key}</p>
    //               <p className="list-none text-base mb-2"> {listItem.value}</p>
    //             </div>
    //           ))}
    //           <a
    //             href="https://hubbleform.com/EjlWx8fiL4Z8kCpnu2Lhre0YGzp2/Form_1"
    //             className="text-inherit hover:text-inherit"
    //           >
    //             <button className=" mt-2 p-2 px-3 bg-[#6C63FF] rounded-full text-base font-medium ">
    //               Submit an enquiry
    //             </button>
    //           </a>
    //         </div>
    //       </div>
    //       <div className="flex flex-col">
    //         <h5 className="text-white  sm:text-[20px] text-sm leading-7 font-semibold mb-3  ">
    //           {/* Switch to {countryName} WebSite */}
    //           COUNTRY
    //         </h5>
    //         <div className="flex flex-col gap-5 items-start sm:w-44 w-36">
    //           {/* <h5
    //             className={`text-white  text-base sm:text-[20px] font-semibold mb-3  cursor-pointer hover:text-black  ${
    //               country === "India" && " text-black"
    //             }`}
    //             onClick={() => onChange(false)}
    //           >
    //             India
    //           </h5>
    //           <h5
    //             className={`text-white  text-base sm:text-[20px] font-semibold mb-3  cursor-pointer hover:text-black ${
    //               country !== "India" && "  text-black"
    //             }`}
    //             onClick={() => onChange(true)}
    //           >
    //             Australia
    //           </h5> */}
    //           <div
    //             className={`rounded-full border px-4 py-2 flex  gap-3 cursor-pointer items-center`}
    //             onClick={() => setopenSelectCountry((prev) => !prev)}
    //           >
    //             <img src={countryLogo} alt="logo" className="h-5 w-5" />
    //             <div className="flex items-end gap-2">
    //               <h5 className="text-white sm:text-[20px] text-sm  font-semibold">{countryName}</h5>
    //               <CaretDownOutlined />
    //             </div>
    //           </div>
    //           <div>
    //             {openSelectCountry && (
    //               <div className="bg-[#f9fafb] rounded-lg shadow-md flex flex-col gap-3 p-4 ease-in-out transition-all ">
    //                 <div
    //                   className="flex gap-3 border border-[#d3329d] rounded-full px-4 py-2 cursor-pointer"
    //                   onClick={() => onChange(false)}
    //                 >
    //                   <img src={India} alt="logo" className="h-5 w-5" />
    //                   <div className="flex items-center">
    //                     <h5 className=" sm:text-[20px] text-sm  font-semibold text-black">India</h5>
    //                   </div>
    //                 </div>
    //                 <div
    //                   className="flex gap-3 border border-[#d3329d] rounded-full px-4 py-2 cursor-pointer"
    //                   onClick={() => onChange(true)}
    //                 >
    //                   <img src={Australia} alt="logo" className="h-5 w-5 object-cover" />
    //                   <div className="flex items-center">
    //                     <h5 className=" sm:text-[20px] text-sm  font-semibold text-black">Australia</h5>
    //                   </div>
    //                 </div>
    //               </div>
    //             )}
    //           </div>
    //         </div>

    //         {/* <Switch onChange={onChange} /> */}
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <footer className=" bg-[#0631A1] px-6 sm:px-16 md:px-24 lg:px-36 xl:px-52 text-white font-normal py-6 space-y-6">
      <div className="flex flex-col gap-6 sm:gap-0 sm:flex-row sm:items-center justify-between">
        <a href="https://colakin.com/about-us.html#get_in_touch">
          <div className="flex gap-4 sm:gap-0 items-end">
            <img src={connect} alt="connect with us" className="w-32 h-16 object-contain" />
            <button className="border border-white  rounded-full flex items-center font-bold justify-center text-base pb-1 h-5 w-5">
              &#8594;
            </button>
          </div>
        </a>
        <ul className="text-[13px] space-y-3 mt-7  sm:text-right">
          {sec2data.map((item, i) => (
            <li key={i}>
              <Link to={item.link}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 justify-between pt-6">
        <a href="https://www.linkedin.com/company/colakin/" target="_blank">
          <img src={linkedIn} alt="linkedin" className="w-6 h-6" />
        </a>
        <div className="border border-gray-100 sm:w-1/2 md:w-1/2 lg:w-2/3 " />
        <ul className="flex text-[13px] space-x-3">
          <li>Adjust consent</li>
          <li>Privacy Policy</li>
        </ul>
      </div>
      <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-4 sm:gap-0">
        <ul className="flex text-[13px] space-x-3">
          {/* <a href="https://colakin.com/about-us.html#contacts"> */}
          <li className={`cursor-pointer  ${country !== "India" && " underline"} `} onClick={() => onChange(true)}>
            Australia
          </li>
          {/* </a> */}
          {/* <a href="https://colakin.com/about-us.html#contacts"> */}
          <li className={`cursor-pointer  ${country === "India" && " underline"} `} onClick={() => onChange(false)}>
            India
          </li>
          {/* </a> */}
          {/* <a href="https://colakin.com/about-us.html#contacts">
            <li>United States</li>
          </a> */}
        </ul>
        <p className="text-[12px]">Copyright © 2023 Colakin - All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
