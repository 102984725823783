import React from "react";
import { Helmet } from "react-helmet";
import Sec1 from "./components/Sec1";
import Sec2 from "./components/Sec2";
import Sec3 from "./components/Sec3";
import Sec4 from "./components/Sec4";
import { seo } from "../../utils/seo";
import Sec5 from "./components/Sec5";
import Sec6 from "./components/Sec6";

const HomePage = () => {
  return (
    <main>
      <Sec1 />
      <Sec2 />
      <Sec3 />
      <Sec4 color="green" shadow title="Provider Experience" />
      <Sec4 color="purple" title="Supplier Experience" />
      <Sec4 color="pink" shadow title="Aged Care Experience" />
      <Sec5 />
      <Sec6 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seo.Australia.title}</title>
        <link rel="canonical" href="https://www.carecommerce.in/" />
        <meta name="description" content={seo.Australia.description} />
      </Helmet>
    </main>
  );
};

export default HomePage;
