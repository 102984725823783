import React from "react";
import styles from "../../../../styles";
import { sec3data } from "./data";

const Sec3 = ({ path }) => {
  let data;
  if (path === "claims") {
    data = sec3data.claims;
  } else if (path === "invoice") {
    data = sec3data.invoice;
  } else {
    data = sec3data.payment;
  }
  return (
    <section
      className={`bg-[#F9FAFB] ${styles.xPaddings} xl:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)] max-h-max `}
    >
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6 items-center sm:flex-col sm:h-full  flex flex-col justify-evenly gap-6 sm:gap-0  md:gap-4`}
      >
        <div className="flex items-center gap-3">
          <img src={sec3data.icon1} alt="" className="w-6 h-6" />
          <h1 className="text-[19px] font-bold">Value to the business</h1>
        </div>

        <div className="space-y-4">
          {data.map((item, i) => (
            <div className="flex p-4 items-start justify-between bg-white rounded-md space-x-4">
              <div className="flex space-x-4">
                <p className="text-base font-semibold">0{i + 1}</p>
                <p className="text-base leading-6 text-justify">{item}</p>
              </div>
              <img src={sec3data.icon2} alt="downarrow" className="w-6 h-6" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec3;
