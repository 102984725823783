import { createContext, useReducer } from "react";
import ChangeCountryReducer from "./ChangeCountryReducer";

const CurrentCountry = new Date()
  .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
  .substring(4)
  .split(" ")[0];

const INITIAL_STATE = {
  country: CurrentCountry,
};

export const CountryContext = createContext(INITIAL_STATE);

export const CountryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChangeCountryReducer, INITIAL_STATE);

  return <CountryContext.Provider value={{ country: state.country, dispatch }}>{children}</CountryContext.Provider>;
};
