import React from "react";
import styles from "../../styles";
import { requirements, details } from "./data";

const Career = () => {
  return (
    <section className={`${styles.xPaddings} mt-16 sm:mt-16 `}>
      <div className={`${styles.innerWidth} mx-auto py-6`}>
        <div className="job-listing bg-white rounded-lg p-4 space-y-6">
          <h1 className="text-lg font-semibold  text-[#9a4dff]">Full Stack Developer Intern</h1>
          <div className="text-sm space-y-3 ">
            {details.map((item) => (
              <p>
                <span class="font-semibold">{item.key}</span> : {item.value}
              </p>
            ))}
          </div>
          <div className="space-y-3">
            <h1 className="text-3xl font-semibold">Job Description:</h1>
            <p className="text-sm leading-6">
              We are seeking a highly motivated and skilled full-stack developer intern to join our team. As a
              full-stack developer intern, you will work on various projects. You will have the opportunity to gain
              experience in all aspects of software development, from design to deployment.
            </p>
          </div>
          <div className="space-y-3">
            <p className="text-base  font-semibold">Required Qualifications:</p>
            <ul className="list-inside list-disc text-sm space-y-2">
              {requirements.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
          <p className="text-base font-semibold ">
            Education: Students enrolled in their final year of college or pre-final year of college are eligible to
            apply.
          </p>
          <p className="text-sm leading-6">
            We offer a competitive stipend and an opportunity to work with a talented and dynamic team on real-time
            projects. If you are passionate about software development and are looking for a challenging and rewarding
            internship, Apply today!
          </p>
          <button className="uppercase text-white bg-[#9a4dff] p-2 px-3 tracking-wider rounded-full hover:bg-purple-600 text-sm font-semibold">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfoaEChJr-jfLwfy0v6N49eFOgx4c4Gzv_MkEIyUDZ0iJbziQ/viewform?vc=0&c=0&w=1&flr=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              apply here
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Career;
