import React from "react";
import styles from "../../../styles";
import { sec2data } from "./data";

const Sec2 = () => {
  return (
    // <section className="bg-[#F9FAFB] xl:p-11  py-0 px-2 sm:px-6 sm:mt-24 mt-6 h-[100vh] sm:h-fit flex flex-col justify-evenly">
    <section
      className={`bg-[#F9FAFB] ${styles.xPaddings} md:h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] sm:min-h-[calc(100vh-4rem)] max-h-max`}
    >
      <div
        className={`${styles.innerWidth}  mx-auto sm:py-6 py-6  sm:h-full  flex flex-col justify-evenly gap-6 sm:gap-0 `}
      >
        <div className="text-center">
          <h1 className="xl:text-xl font-bold text-[22px] leading-8">
            {/* Collect your invoice once. Empower every team. */}
            Collect Invoice. Get Paid. Submit Claim.
          </h1>
          <span className="xl:text-base text-[16px] leading-8">
            {/* Our technology automates and inegrate end to end claim settlement process. Empowering the Aged Care Industry */}
            A fully integrated suite of invoice to claim, invoice to payment processing and everything between suppliers
            and provider.
          </span>
        </div>
        <div className="flex xl:justify-between items-center flex-col md:flex-row xl:flex-nowrap justify-evenly xl:gap-0 gap-6 lg:justify-evenly w-full">
          {sec2data.map((item, i) => (
            <div
              className="flex flex-col sm:h-fit justify-evenly gap-4 last:mb-0 sm:mb-0 md:w-[30%] items-center sm:items-start"
              key={i}
            >
              <div className="flex flex-col sm:gap-4 gap-4 ">
                <div className="flex flex-col gap-3 items-center">
                  <img src={item.img} alt="thumb" className="w-56  h-60 sm:w-[250px] sm:h-[280px] object-contain " />
                </div>
                <div className="flex flex-1 flex-col sm:items-start items-center">
                  <h3 className="text-[18px] sm:text-[20px] leading-7 font-semibold">{item.title}</h3>
                  <span className="text-[15px]  text-[#999999]  ">{item.subtitle}</span>
                </div>
                <span className=" text-[16px] leading-8 sm:text-base sm:leading-8 text-center sm:text-start">
                  {item.desc}
                </span>
              </div>
              {/* <span className="flex  text-[#6C63FF] cursor-pointer items-center">Read more... {"     "}&#8594;</span> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sec2;
