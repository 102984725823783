import sec1Img from "../../../assets/aus/sec1/sec1 copy.svg";
import Providers from "../../../assets/aus/sec1/sec1_Providers.svg";
import Suppliers from "../../../assets/aus/sec1/sec1_Suppliers.svg";
import Recipent from "../../../assets/aus/sec1/sec1_Recipent.svg";
import NDIS from "../../../assets/aus/sec1/sec1_NDIS.svg";
import mobileMain from "../../../assets/aus/sec1/mobileMain.svg";
import sec1Img2 from "../../../assets/aus/sec1/main2.svg";
import styles from "../../../styles";

function Sec1() {
  const Imgdata = [
    {
      title: "Providers",
      img: Providers,
    },
    {
      title: "Suppliers",
      img: Suppliers,
    },
    {
      title: "Recipent",
      img: Recipent,
    },
    {
      title: "NDIS",
      img: NDIS,
    },
  ];
  return (
    <section
      className={`${styles.xPaddings} mt-16 sm:mt-16 sm:h-[calc(100vh-4rem)] max-h-[calc(100vh-4rem)] sm:max-h-max`}
    >
      <div
        className={`${styles.innerWidth} mx-auto flex flex-col items-center sm:items-start sm:flex-col justify-evenly sm:justify-around  lg:h-full   py-6`}
      >
        {/* <div className="flex flex-col items-center xl:items-start  xl:flex-row xl:justify-evenly p-2 "> */}
        <div className="flex flex-col gap-20 ]">
          <div className=" flex flex-col  sm:justify-start space-y-4">
            {/* <div className="w-[60%]"> */}
            <h1 className="lg:text-[35px] lg:leading-[50px] font-bold sm:text-[30px] text-[26px] hidden sm:block ">
              {/* Restructure your business through our intelligent automation. */}
              Pay Like Never Before
            </h1>
            <h1 className="font-bold  text-[22px] sm:hidden  text-center leading-8 ">Empower Your Business</h1>
            <p className="sm:text-[20px] text-[17px] hidden sm:inline w-[60%] ">
              {/* With the help of modern technologies, we accumulate and improve the processing timelines for claims
              management, settlement, and resolution. */}
              A one stop solution for your aged care and NDIS invoicing, claims, payment and our intelligent automation
              streamline your supplier payments.
            </p>
            <button className=" hidden sm:flex rounded-full bg-[#6C63FF] w-fit text-white font-semibold text-base px-3 py-1">
              Learn More...
            </button>
          </div>
          {/* <img src={sec1Img2} alt="" className="h-fit w-[20vw] hidden sm:inline" /> */}
        </div>
        {/* <div className="absolute right-0 bottom-[18%]"> */}
        <div className="flex md:items-start xl:items-center mt-4 xl:mt-0 xl:gap-4 gap-0 flex-col xl:flex-row">
          {/* <div className="flex items-center "> */}
          {/* <img src={sec1Img} alt="logo" className="xl:w-[78%] xl:-ml-[26rem]  w-full hidden sm:inline-block" /> */}
          {/* <img src={sec1Img2} alt="" className="h-fit w-[20vw] hidden sm:inline mr-5" /> */}
          <img src={sec1Img2} alt="" className="h-fit lg:w-[20vw]  w-[26vw] hidden sm:inline" />

          <img
            src={sec1Img}
            alt="logo"
            className=" max-h-[435px] md:-mt-36 lg:-mt-40 -mt-20  xl:-mt-28  object-cover w-full hidden sm:inline-block "
          />
          {/* <img
            src={sec1Img}
            alt="logo"
            className=" flex-grow xl:w-full h-[470px] object-cover w-full hidden sm:inline-block -mr-16 -mt-6"
          /> */}
        </div>
        <div className="flex items-center">
          <img src={mobileMain} alt="logo" className="h-[60vh] sm:hidden " />
        </div>

        <div className="flex items-center sm:justify-evenly xl:gap-10 gap-8 mt-6 xl:-mt-6  md:-mt-10 flex-wrap sm:flex-nowrap justify-evenly sm:hidden">
          {Imgdata.map((item, i) => (
            <div className="flex flex-col items-center sm:min-w-[112px]" key={i}>
              <img
                src={item.img}
                alt=""
                className="xl:w-[100px] xl:h-[100px] md:w-20 md:h-20 lg:w-24 lg:h-24 sm:w-20 sm:h-20 w-[50px] h-[50px]"
              />
              <h3 className=" text-[14px] sm:text-md font-semibold">{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default Sec1;
