import React from "react";
import "./styles/App.css";
import Layout from "./Pages/Layout";
import { Helmet } from "react-helmet";
import { seo } from "./utils/seo";

function App() {
  return (
    <div className=" bg-white overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seo.index.title}</title>
        <link rel="canonical" href="https://www.carecommerce.in/" />

        <meta name="description" content={seo.Australia.description} />
      </Helmet>
      <Layout />
    </div>
  );
}

export default App;
