// import dba from "../../../assets/aus/sec4/DBA.svg";
// import BE from "../../../assets/aus/sec4/BAE.svg";
// import PE from "../../../assets/aus/sec4/PE.svg";
// import IDQ from "../../../assets/aus/sec4/IDQA.svg";
import SAAS from "../../../assets/aus/sec4/SAAS.svg";
import SelfHosted from "../../../assets/aus/sec4/SelfHosted.svg";
import B2G from "../../../assets/aus/sec4/B2G.svg";
import Cloud from "../../../assets/aus/sec4/Cloud.svg";
import InvoiceReceive from "../../../assets/aus/sec4/green/InvoiceReceive.svg";
import ClaimSubmit from "../../../assets/aus/sec4/green/ClaimSubmit.svg";
import SupplierPayment from "../../../assets/aus/sec4/SupplierPayment.svg";
import DeliveryService from "../../../assets/aus/sec4/DeliveryService.svg";
import SendInvoice from "../../../assets/aus/sec4/SendInvoice.svg";
import PaymentSummary from "../../../assets/aus/sec4/pink/gp.svg";
import ApproveInvoice from "../../../assets/aus/sec4/pink/ai.svg";
import rs from "../../../assets/aus/sec4/pink/rs.svg";
import PaymentReceived from "../../../assets/aus/sec4/PaymentReceived.svg";

//icons
import RI from "../../../assets/aus/sec4/green/icons/RI.svg";
import SC from "../../../assets/aus/sec4/green/icons/SC.svg";
import PS from "../../../assets/aus/sec4/green/icons/PS.svg";
import DS from "../../../assets/aus/sec4/icons/DS.svg";
import SI from "../../../assets/aus/sec4/icons/SI.svg";
import GP from "../../../assets/aus/sec4/icons/GP.svg";
import RS from "../../../assets/aus/sec4/pink/icons/rs.svg";
import AI from "../../../assets/aus/sec4/pink/icons/ai.svg";
import GPS from "../../../assets/aus/sec4/pink/icons/rs.svg";

import { BE, IDQ, PE, dba } from "../../../assets/ind/sec8";
import { CB, PWB, SH, saas } from "../../../assets/ind/sec7";

import Invoice from "../../../assets/aus/sec2/Invoice1.svg";
import Claims from "../../../assets/aus/sec2/Claim1.svg";
import Payment from "../../../assets/aus/sec2/Payment1.svg";

export const sec2data = [
  {
    // img: Invoice,
    img: Invoice,
    title: "Invoice",
    subtitle: "Intelligent Invoice Processing",
    desc: "Accept all types of invoices, integrate with email, xero, and major accounting software and transform into claims.",
    link: "invoice",
  },
  {
    // img: Claims,
    img: Claims,
    title: "Claims",
    subtitle: "Business to government claim",
    desc: "Validate, approve of every invoice, fulfillment claim process, simplify settlements, and shorten processing times",
    link: "claims",
  },
  {
    // img: Payment,
    img: Payment,
    title: "Payment",
    subtitle: "Automate payment processing",
    desc: "Automated payment processing not only streamlines payment approval but also ensures timely consolidation and sending of payments.",
    link: "payment",
  },
];

export const green = [
  {
    icon: RI,
    name: "Provider",
    title: "Receive an Invoice",
    desc: "On a single platform, aged care providers can gather and examine hundreds of invoices, after the service is delivered.",
    img: InvoiceReceive,
  },
  {
    icon: SC,
    name: "Supplier",
    title: "Submit the Claim",
    desc: "On behalf of providers, We will send the claim form to B2G after extracting the important information from the invoice the provider submitted",
    img: ClaimSubmit,
  },
  {
    icon: PS,
    name: "Aged Care Person",
    title: "Payment to Supplier",
    desc: "we  will automatically verify the claim amount,  send the shortfall message, and ask clients to pay providers on their behalf if the claim amount is lower than the service amount.",
    img: SupplierPayment,
  },
];

export const purple = [
  {
    icon: DS,
    title: "Delivery Service",
    desc: "Clients are given a flowchart to follow the claim procedure, and they can afterwards approve the invoice.",
    img: DeliveryService,
  },
  {
    icon: SI,
    title: "Send the Invoice",
    desc: "Aged care suppliers have various invoice submission options, including Dropbox, email, or the system. Suppliers can easily input mailing details like email addresses and Dropbox links using a basic setting.",
    img: SendInvoice,
  },
  {
    icon: GP,
    title: "Get Paid",
    desc: "Colakin Care will check the payment account after receiving customer payments and send supplier payments. Through Colakin Care, suppliers can view the payment information.",
    img: PaymentReceived,
  },
];

export const pink = [
  {
    icon: RS,
    title: "Receive Service",
    desc: "Service can be requested with a click through our technology, and you will receive the requested service",
    img: rs,
  },
  {
    icon: AI,
    title: "Approve the Invoice",
    desc: "Clients are given a flowchart to follow the claim procedure, and they can afterwards approve the invoice.",
    img: ApproveInvoice,
  },
  {
    icon: GPS,
    title: "Get Payment Summary",
    desc: "Aged care individuals can select the payment statement frequency, which includes key details like service duration for specific suppliers/providers or multiple service types.",
    img: PaymentSummary,
  },
];

export const features = [
  {
    img: dba,
    title: "Drive Business Agility",
    desc: "The efficient claims processing system offers accessible data. Clients navigate the procedure effortlessly through a user-friendly flowchart. Real-time dashboard updates enhance client visibility and involvement.",
  },
  {
    img: BE,
    title: "Builds an Ecosystem",
    desc: "The integrated platform in the aged care sector fosters seamless connectivity and enhances user experience. Aged care providers can leverage the community for innovation and added value.",
  },
  {
    img: PE,
    title: "Process Evolution",
    desc: "Through multithreading and computational capacity, automated invoice processing tools, such as signature verification and optical character recognition (OCR), significantly reduce claim settlement processing time.",
  },
  {
    img: IDQ,
    title: "Increase Data Quality and Accuracy",
    desc: "Our data-driven intelligent automation technology eliminates the risk of human error in business operations by relying on datasets for decision-making.",
  },
];

export const HowWeBuild = [
  {
    img: saas,
    title: "Saas",
    desc: "Our SaaS-based cloud computing platform called Care Commerce gives customers access to hosted development kits, application management tools, and database tools that businesses need to create, set up, and launch software applications.",
  },
  {
    img: SH,
    title: "Self Hosted",
    desc: "Being a self-hosted platform, care commerce has open source applications that run via web hosting. Care commerce is a decentralised platform that allows users to host services as they see fit.",
  },
  {
    img: PWB,
    title: "Partner with B2G",
    desc: "Care Commerce is a B2G eCommerce platform that streamlines claim settlement by connecting to the government site for aged care services. Our platform integrates with the end-to-end Open Banking platform to efficiently authorize and send funds to aged care providers, ensuring smooth claim settlement.",
  },
  {
    img: CB,
    title: "Cloud Based",
    desc: "Care Commerce is a system of cloud-based infrastructure that provides low-cost means to deliver claim management, settlement, and resolution processes to banks, governmental organisations, aged care providers, aged care service suppliers, and elderly people.",
  },
];
