export const seo = {
  index: {
    name: "Care Commerce",
    description: "This is an official Care Commerce Page",
    title: "Care Commerce",
  },
  Australia: {
    name: "Care Commerce",
    description: "This is an official Care Commerce Page of Australia",
    title: "Care Commerce / Australia",
  },
  Invoice: {
    name: "Care Commerce Invoice Page",
    description: "This is a Invoice Section of Care Commerce Australia",
    title: "Care Commerce / Invoice",
  },
  Claims: {
    name: "Care Commerce Claims Page",
    description: "This is a Claims Section of Care Commerce Australia",
    title: "Care Commerce / Claims",
  },
  Payment: {
    name: "Care Commerce Payment Page",
    description: "This is a Payment Section of Care Commerce Australia",
    title: "Care Commerce / Payment",
  },
};
